import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {RegistrationAttemptService} from "../../services/registration-attempt.service";
import {Timestamp} from "firebase/firestore";
import {RegistrationAttempt} from "../../models/registration-attempt";
import {ThemingService} from "../../../tandem-core/services/theming.service";

@Component({
  selector: 'tandem-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%^&*])[A-Za-z\d@$!%^&*]{8,}$/;
  public registerForm: FormGroup = new FormGroup<any>({});

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  coachId: string | null = null;
  registrationMode: string | null = null; // coachRevShare || coachLeadGen
  preventButtonPress = false;

  // passwordErrorMessage: string | null = null;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private registrationAttemptService: RegistrationAttemptService,
              private fb: FormBuilder) {
    this.coachId = this.route.snapshot.queryParamMap.get('coachId');
    this.registrationMode = this.route.snapshot.queryParamMap.get('registrationMode');
  }

  ngOnInit() {
    this.themingService.brandingConfig.subscribe(config => {
      this.logoPath = config.logoURL;
    })
    this.auth.$user.subscribe(user => {
      if (user) {
        this.router.navigate(['/dashboard'], { queryParamsHandling: 'preserve' });
      }
    })
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    }, {
      // validators: this.matchValuesValidator('password', 'confirmPassword')
    });
    this.registerForm.get('password')?.valueChanges.subscribe(pass => {
      if(this.registerForm.get('password')?.dirty) {
       // this.passwordErrorMessage = this.validatePassword(pass);
      }
    })
  }

  onGoogleLogin() {
    if (!this.preventButtonPress) {
      // this.preventButtonPress = true;
      this.auth.onGoogleLogin('register').then(r => {}).then(res => {
        this.router.navigate(['/dashboard'])
        this.dialogService.showAlert({title: 'Logged In', content: 'Welcome!', dismissTime: 3000, position: 'bottom'})
      }).catch(err => {
        this.preventButtonPress = false;
      }).finally(() => {
        // Reset the button state in case of success without navigation
        this.preventButtonPress = false;
      });
    }
  }
  displayError(): void {
    this.errorMessage = "Email and Password Required."
      setTimeout(() => {
        this.errorMessage = ""
      }, 3000)
    this.preventButtonPress = false;
  }

  registerWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      if (this.registerForm.invalid) {
        this.displayError()
      } else {
        this.saveRegistrationInfo().then(res => {
          this.auth.registerWithEmailPass(
            this.registerForm.get('email')?.value,
            this.registerForm.get('password')?.value,
            this.coachId,
          ).then(res => {this.preventButtonPress = false;}).catch(error => {this.preventButtonPress = false;})
        }).catch(err => {
          this.preventButtonPress = false;
        }).finally(() => {
          // Reset the button state in case of success without navigation
          this.preventButtonPress = false;
        });
      }
    }
  }

  private matchValuesValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ mismatch: true });
        return { mismatch: true };
      } else {
        matchingControl?.setErrors(null);
        return null;
      }
    };
  }

  // private validatePassword(password: string): string | null {
  //   console.log(password)
  //   if (!/(?=.*\d)/.test(password)) {
  //     this.registerForm.get('password')?.setErrors({digit: true})
  //     return "Password must contain at least one digit.";
  //   }
  //   if (!/(?=.*[@$!%^&*])/.test(password)) {
  //     this.registerForm.get('password')?.setErrors({specialChar: true})
  //     return "Password must contain at least one special character (@, $, !, %, ^, &, *).";
  //   }
  //   if (!/^[A-Za-z\d@$!%^&*]{8,}$/.test(password)) {
  //     this.registerForm.get('password')?.setErrors({length: true})
  //     return "Password must be at least 8 characters long.";
  //   }
  //   return null; //password is valid
  // }

  private saveRegistrationInfo(): Promise<RegistrationAttempt> {
    return this.registrationAttemptService.add({
      email: this.registerForm.get('email')?.value,
      coachId: this.coachId || environment.defaultCoachId,
      dateModified: Timestamp.now(),
      dateCreated: Timestamp.now(),
      registrationMode: this.registrationMode,
      accountType: this.registrationMode ? 'coach' : 'user'
    })
  }

  protected readonly environment = environment;

  routeWithParams(s: string) {
    this.router.navigate([s], {
      queryParamsHandling: 'preserve'
    })
  }
}
