<div class="w-full relative" style="height: 100vh">
<!--  <div *ngIf="bannerText" class="flex justify-center items-center w-full fixed bottom-0 bg-primary z-40"><h1 class="text-white">{{bannerText}}</h1></div>-->
  <tandem-chat class="fixed bottom-8 right-8 z-100"></tandem-chat>
  <ng-container *ngIf="!user">
    <router-outlet #o="outlet"></router-outlet>
  </ng-container>
  <ng-container *ngIf="user">
    <div>
      <div class="relative z-50 lg:hidden" role="dialog" aria-modal="true" *ngIf="showTWSidebar && displayNavStack">

        <div class="fixed inset-0 bg-gray-900/80"></div>
        <div class="fixed inset-0 flex">
          <div class="relative mr-32 flex w-full max-w-xs flex-1">
            <div class="absolute left-full top-0 flex w-16 justify-center pt-5">

            </div>

            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary pb-4">
              <div class="flex h-16 shrink-0 items-center justify-between">
                <img style="cursor: pointer" class="h-10 w-auto" [src]="sidenavState === 'collapsed' ? whiteIconPath : whiteLogoPath" alt="Tandem Finance">
                <button type="button" class="-m-2.5 p-2.5" (click)="showTWSidebar = false">
                  <span class="sr-only">Close sidebar</span>
                  <svg class="h-8 w-8 text-white pr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <nav class="flex flex-1 flex-col px-6">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <!-- Current: "bg-primary text-white", Default: "text-white hover:text-white hover:bg-primary" -->
                    <a [routerLink]="'dashboard'" (click)="showTWSidebar = false" class=" text-white group flex gap-x-3 rounded-md  text-sm leading-6 font-semibold" [ngClass]="{'p-2': sidenavState === 'expanded'}" [routerLinkActive]="'font-bold underline'">
                      <svg class="h-6 w-6 shrink-0 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                      </svg>
                      Dashboard
                    </a>
                  </li>
                  <ng-container *ngFor="let section of navSections">
                    <li *tandemAuth="section.authProp">
                      <div *ngIf="section.label" class="text-xs font-semibold leading-6 text-white">{{ section.label }}</div>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li *ngFor="let item of section.items">
                          <a [routerLinkActive]="'font-bold underline'" [routerLink]="item.routerLink" (click)="showTWSidebar = false" class="text-white hover:text-white hover:bg-primary group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold whitespace-nowrap">
                            <ng-container [ngSwitch]="item.label">
                              <svg *ngSwitchCase="fpProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                              </svg>
                              <svg *ngSwitchCase="cfProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                              </svg>
                              <svg *ngSwitchCase="spProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                              </svg>

                              <svg *ngSwitchCase="'Debt Payoff'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
                              </svg>
                              <svg *ngSwitchCase="'Investment'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                              </svg>
                              <svg *ngSwitchCase="'Debt Vs. Invest'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                              </svg>
                            </ng-container>
                            {{item.label}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ng-container>
                  <li class="mt-auto flex justify-start items-center">
                    <a [routerLink]="'auth/profile'" (click)="showTWSidebar = false" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-primary hover:text-white">
                      <svg class="h-6 w-6 shrink-0 text-white group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Settings
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayNavStack" class="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex transition-all duration-500 ease-in-out" [ngClass]="sidenavState === 'collapsed' ? 'lg:w-20' : 'lg:w-72'">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-primary pb-4 transition-all duration-500 overflow-x-clip relative">
          <div class="flex h-16 shrink-0 items-center" [ngClass]="sidenavState === 'collapsed' ? 'mt-5' : 'mt-20'">
            <img [ngClass]="sidenavState === 'collapsed' ? 'w-full h-fit' : 'h-24 w-auto'" [routerLink]="'dashboard'" [src]="sidenavState === 'collapsed' ? whiteIconPath : whiteLogoPath" alt="Tandem Finance">
          </div>
          <nav class="flex flex-1 flex-col px-6">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <a (mouseenter)="showTooltip = 'dashboard'" (mouseleave)="showTooltip = null" [routerLink]="'dashboard'" class="text-white group flex gap-x-3 rounded-md text-sm leading-6 font-semibold" [ngClass]="{'p-2': sidenavState === 'expanded'}" [routerLinkActive]="'font-bold underline'">
                  <svg class="h-6 w-6 shrink-0 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                  <div *ngIf="sidenavState === 'collapsed' && showTooltip === 'dashboard'"  class="fixed left-16 mb-2 -translate-y-4 px-2 py-1 bg-white text-black border border-primary text-sm rounded-md w-fit z-50" style="width: max-content; max-width: 250px">
                    <!-- Tooltip Text -->
                    Dashboard
                  </div>
                  <ng-container *ngIf="sidenavState === 'expanded'">Dashboard</ng-container>
                </a>
              </li>
              <ng-container *ngFor="let section of navSections">
                <li *tandemAuth="section.authProp">
                  <div *ngIf="section.label && sidenavState === 'expanded'" class="text-xs font-semibold leading-6 text-white">{{ section.label }}</div>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li *ngFor="let item of section.items">
                      <a (mouseenter)="showTooltip = item.label" (mouseleave)="showTooltip = null" [routerLinkActive]="'font-bold underline'" [routerLink]="item.routerLink" class="relative text-white hover:text-white hover:bg-primary group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                        <ng-container [ngSwitch]="item.label">
                          <svg *ngSwitchCase="fpProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                          </svg>
                          <svg *ngSwitchCase="cfProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                          </svg>
                          <svg *ngSwitchCase="spProp" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                          </svg>

                          <svg *ngSwitchCase="'Debt Payoff'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
                          </svg>
                          <svg *ngSwitchCase="'Investment'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                          </svg>
                          <svg *ngSwitchCase="'Debt Vs. Invest'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                          </svg>
                          <svg *ngSwitchCase="'User Management'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                          </svg>
                          <svg *ngSwitchCase="'Coach Management'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                          </svg>
                        </ng-container>
                        <div *ngIf="sidenavState === 'collapsed' && showTooltip === item.label"  class="fixed left-16 mb-2 -translate-y-4 px-2 py-1 bg-white text-black border border-primary text-sm rounded-md w-fit z-50" style="width: max-content; max-width: 250px">
                          <!-- Tooltip Text -->
                          {{ item.label }}
                        </div>
                        <ng-container *ngIf="sidenavState === 'expanded'">{{item.label}}</ng-container>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
              <li class="mt-auto flex justify-between items-center" [ngClass]="sidenavState === 'collapsed' ? 'flex-col' : 'flex-row'">
                <a (mouseenter)="showTooltip = 'settings'" (mouseleave)="showTooltip = null" [routerLink]="'auth/profile'" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-primary hover:text-white">
                  <svg class="h-6 w-6 shrink-0 text-white group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <div *ngIf="sidenavState === 'collapsed' && showTooltip === 'settings'"  class="fixed left-16 mb-2 -translate-y-4 px-2 py-1 bg-white text-black border border-primary text-sm rounded-md w-fit z-50" style="width: max-content; max-width: 250px">
                    <!-- Tooltip Text -->
                    Settings
                  </div>
                  <ng-container *ngIf="sidenavState === 'expanded'">Settings</ng-container>
                </a>
                <a (click)="toggleSidenavState()" class="text-white hover:cursor-pointer" [ngClass]="sidenavState === 'collapsed' ? 'mt-3' : ''">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" [class]="sidenavState === 'collapsed' ? 'w-6 h-6 transition duration-500' : 'w-6 h-6 transform rotate-180 transition duration-500'">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div [ngClass]="sidenavState === 'collapsed' ? 'lg:pl-20' : 'lg:pl-72'" class="transition-all duration-500">
        <div class="sticky top-0 z-40 flex flex-row lg:justify-between h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8" *ngIf="displayNavStack">
          <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" (click)="showTWSidebar = true">
            <span class="sr-only">Open sidebar</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>

          <!-- Separator -->
          <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>

          <div class="flex flex-row justify-between w-full">
            <nav class="flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center lg:space-x-4">
                <li class="hidden lg:inline-block">
                  <div>
                    <a [routerLink]="'dashboard'" class="text-gray-400 hover:text-gray-500 hover:cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                      </svg>
                      <span class="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                <li *ngFor="let crumb of headerConfig?.breadcrumbs" class="hidden lg:inline-block">
                  <div class="flex items-center">
                    <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                    <a [routerLink]="crumb.route" class="ml-4 text-sm font-medium text-gray-500" [ngClass]="{'hover:text-gray-700 hover:cursor-pointer': !!crumb.route}">{{ crumb.label }}</a>
                  </div>
                </li>
              </ol>
            </nav>
            <div class="flex flex-row justify-between gap-x-4 self-stretch lg:gap-x-6">
              <div class="flex items-center gap-x-4 lg:gap-x-6">
                <div class="relative">
                  <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 relative" (click)="toggleNotificationsDropdown(); $event.stopPropagation()">
                    <!-- Bell icon SVG -->
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                    </svg>

                    <!-- Notification badge -->
                    <span *ngIf="getUnreadNotificationsCount() > 0" class="absolute top-2 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/3 -translate-y-1/3">
    {{ getUnreadNotificationsCount() }}
  </span>
                  </button>

                  <div *ngIf="showNotificationsDropdown" (clickOutside)="closeDropdowns()" class="absolute right-0 z-10 mt-2.5 w-96 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" tabindex="-1">
                    <div class="max-h-96 overflow-y-auto">
                      <ng-container *ngIf="notifications.length > 0">
                        <div class="px-4 flex items-center justify-center gap-8">
                          <button class="text-gray-400 hover:text-gray-900" (click)="markAllNotificationsAsRead()">Mark All As Read</button>
                          <button class="text-gray-400 hover:text-gray-900" (click)="deleteAllNotifications()">Clear Notifications</button>
                        </div>
                        <div *ngFor="let notification of notifications" class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="markAsRead(notification)">
                          <div class="flex items-start gap-2 justify-between">
                            <div [ngClass]="{'font-bold': !notification.isRead}" class="flex-grow">
                              <p class="text-sm text-gray-700">{{ notification.content }}</p>
                              <div class="flex flex-col justify-start gap-2">
                                <p class="text-xs text-gray-500">{{ notification.date.toDate() | date:'short' }}</p>
                              </div>
                            </div>
                            <div class="flex flex-col justify-start items-center justify-between gap-2 h-full">
                              <button (click)="$event.stopPropagation(); deleteNotification(notification)" class="inline-flex items-center rounded-full bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10 px-2 w-fit h-fit hover:bg-gray-200">x</button>
                              <div *ngIf="!notification.isRead" class="w-2 h-2 bg-blue-500 rounded-full"></div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="notifications.length === 0">
                        <div class="py-2 px-6 whitespace-nowrap"><h2>No new notifications!</h2></div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!-- Separator -->
                <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"></div>

                <!-- Profile dropdown -->
                <div class="relative">
                  <button type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true" (click)="toggleDropdown(); $event.stopPropagation()">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full bg-gray-50" [src]="user.photoURL" alt="" *ngIf="user.photoURL">
                    <span *ngIf="!user.photoURL" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary">
                      <span class="text-sm font-medium leading-none text-white">{{ user.firstName?.at(0)?.toUpperCase() }}{{user.lastName?.at(0)?.toUpperCase()}}</span>
                    </span>
                    <!--                <img class="h-8 w-8 rounded-full bg-gray-50" src="https://lh3.googleusercontent.com/a/ACg8ocJkgstKfidsSbJBGGaFNP566FRQDBD_dCCMuqtSx05nQik=s288-c-no" alt="">-->
                    <span class="hidden lg:flex lg:items-center">
                <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{user.displayName}}</span>
                  <svg class="ml-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </span>
                  </button>
                  <div *ngIf="showProfileDropdown" (clickOutside)="closeDropdown()" class="absolute right-0 z-10 mt-2.5 w-fit origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                    <a class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-0" [routerLink]="'auth/profile'" (click)="closeDropdown()">Your profile</a>
                    <a *ngIf="user.accountType !== 'coach'" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer whitespace-nowrap" role="menuitem" tabindex="-1" id="user-menu-item-1" [routerLink]="'file-management'" (click)="closeDropdown()">File Management</a>
                    <a class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-2" (click)="closeDropdown();logout()">Sign out</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main class="">
          <tandem-header *ngIf="!disableHeader && displayNavStack"></tandem-header>
          <div class="px-4 sm:px-6 lg:px-8" [ngClass]="sidenavState === 'collapsed' ? 'lg:px-20 xl:px-30 2xl:px-40 3xl:px-60' : 'lg:px-10 xl:px-20 2xl:px-20 3xl:px-40'">
            <router-outlet #o=outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
  </ng-container>

  <div class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" [hidden]="!showSidePanel">
    <!-- Background backdrop -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
          <!-- Slide-over panel -->
          <div class="pointer-events-auto w-screen max-w-xl">
            <div class="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
              <div class="relative flex-1">
                <ng-container #sidePanelContent></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
