<div class="bg-primary transition-all duration-1000 ease-quad-out w-300 h-full flex flex-col justify-between py-30" (click)="$event.stopPropagation()" [ngClass]="{'w-100': !isExpanded, 'overflow-y-scroll': isExpanded}">
  <div class="flex flex-col justify-start gap-40" [ngClass]="{'items-center': !isExpanded}">
    <img class="transition-transform ease-quad-inout duration-1000" [routerLink]="'dashboard'" (click)="toggleExpanded()" [src]="isExpanded ? environment.whiteLogoPath : environment.whiteIconPath">
<!--    <img class="transition-transform ease-quad-inout duration-1000" [src]="isExpanded ? 'assets/svg/logo/full_white.svg' : 'assets/svg/logo/icon_white.svg'">-->
    <tandem-nav-item *ngIf="displayProfile" class="px-20" [isExpanded]="isExpanded" [navItem]="{label: 'Dashboard', icon: 'dashboard'}" [routerLink]="'dashboard'" (click)="toggleExpanded()"></tandem-nav-item>
    <div *ngFor="let section of sections" class="flex flex-col gap-20 px-20" [ngClass]="{'items-center': !isExpanded}">
      <ng-container *tandemAuth="section.items[0].authProp">
        <h3 *ngIf="isExpanded" class="text-background">{{section.title}}</h3>
        <tandem-nav-item *ngFor="let item of section.items" [isExpanded]="isExpanded" [navItem]="{label: item.label, icon: item.icon}" [routerLink]="item.routerLink" (click)="toggleExpanded()"></tandem-nav-item>
      </ng-container>
    </div>
  </div>
  <div class="flex flex-col items-center gap-40">
    <tandem-icon *ngIf="displayProfile && !isExpanded"  class="hover:cursor-pointer hover:opacity-70" [routerLink]="'auth/profile'" icon="userCircle" [width]="30" [height]="30" color="white" (click)="toggleExpanded()"></tandem-icon>
<!--    <tandem-icon class="hover:cursor-pointer hover:opacity-70" (click)="onLogout()" *ngIf="!isExpanded" icon="logout" [width]="30" [height]="30" color="white"></tandem-icon>-->
<!--    <div class="w-full flex flex-row justify-start items-center gap-5 hover:cursor-pointer hover:opacity-70 px-20" (click)="toggleExpanded()" [routerLink]="'auth/profile'"  *ngIf="displayProfile && isExpanded"><tandem-icon [width]="30" [height]="30" icon="userCircle" color="white"></tandem-icon> <h4 class="text-background">Profile</h4></div>-->
    <div class="w-full flex flex-row {{isExpanded ? 'justify-end px-20' : 'justify-end'}}">
<!--      <div (click)="onLogout()" class="flex flex-row gap-5 items-center hover:cursor-pointer hover:opacity-70" *ngIf="isExpanded"><tandem-icon [width]="30" [height]="30" icon="logout" color="white"></tandem-icon> <h4 class="text-background">Logout</h4></div>-->
      <tandem-icon class="transition-transform duration-200 ease-quad-out hover:cursor-pointer hover:opacity-70" [ngClass]="{'rotate-45': isExpanded}"  [width]="25" [height]="25" icon="playRight" (click)="toggleExpanded()" color="white"></tandem-icon>
    </div>
  </div>
</div>
