<div class="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8" style="height: 100vh">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img [src]="environment.primaryIconPath" class="mx-auto h-40 w-auto">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Additional Info Needed</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
      <h4 class="w-full text-center mb-8">We just need a bit more information about you in order to provide you with the best experience. Please fill out the form below.</h4>
      <form class="space-y-6">
        <tandem-form-input [form]="informationForm"
                           [label]="'First Name'"
                           [controlName]="'firstName'"
                           [inputId]="'firstName'"
                           [placeholder]="'First Name'"></tandem-form-input>
        <tandem-form-input [form]="informationForm"
                           [label]="'Last Name'"
                           [controlName]="'lastName'"
                           [inputId]="'lastName'"
                           [placeholder]="'Last Name'"></tandem-form-input>
        <tandem-form-input [form]="informationForm"
                           [label]="'Phone Number'"
                           [controlName]="'phoneNumber'"
                           [inputId]="'phoneNumber'"
                           [placeholder]="'Phone Number'"
                           [isOptional]="true"></tandem-form-input>
        <div>
          <tandem-button class="w-full mt-2" [onClick]="submit.bind(this)">Submit</tandem-button>
        </div>
      </form>
    </div>
  </div>
</div>
