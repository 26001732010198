<!--<button-->
<!--  (click)="onClick()"-->
<!--  [disabled]="disabled"-->
<!--  [name]="label || name"-->
<!--  [type]="type || ''"-->
<!--  [routerLink]="routerLink"-->
<!--  [ngClass]="buttonVariant[variant] + ' ' + (theme ? buttonTheme[theme] : ' ')"-->
<!--  class="w-full duration-350 disabled:cursor-not-allowed disabled:opacity-30 flex-center group transition-quad-inout hover:opacity-70"-->
<!--&gt;-->
<!--  <div-->
<!--    *ngIf="variant === 'circle' || variant === 'icon'"-->
<!--    [ngClass]="-->
<!--      variant === 'circle'-->
<!--        ? 'bg-primary mr-sm flex-center mr-5 square-32 rounded-full'-->
<!--        : '' + variant === 'icon'-->
<!--        ? 'mr-5'-->
<!--        : ''-->
<!--    "-->
<!--    class="flex-center duration-350  transition-quad-inout p-3 me-10"-->
<!--  >-->
<!--      <div>-->
<!--        <tandem-icon *ngIf="variant === 'circle'"-->
<!--          [icon]="icon!"-->
<!--          color="white"-->
<!--          [width]="15"-->
<!--          [height]="15"-->
<!--        ></tandem-icon>-->
<!--      </div>-->
<!--  </div>-->
<!--  <span class="flex gap-5 flex-row">-->
<!--    <tandem-icon [width]="15" [height]="15" *ngIf="variant !== 'circle' && icon" [icon]="icon" [color]="theme === 'solid' ? 'white' : color"></tandem-icon>-->
<!--    <ng-content></ng-content>-->
<!--  </span>-->
<!--</button>-->

<button
  (click)="onClick()"
  [type]="type"
  class="w-full rounded-md bg-primary px-2.5 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary flex gap-2 justify-center items-center"
  [ngClass]="{'bg-red-600': color === 'red', 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50': color === 'white', 'text-white': ['primary', 'red'].includes(color), 'opacity-60 cursor-not-allowed': disabled}"
  [disabled]="disabled">
  <tandem-icon *ngIf="icon"
               [icon]="icon!"
               color="white"
               [width]="18"
               [height]="18"
  ></tandem-icon>
  <ng-content></ng-content>
</button>
