import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {environment} from "../../../../../environments/environment";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {ForgotPasswordDialogComponent} from "../forgot-password-dialog/forgot-password-dialog.component";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {ThemingService} from "../../../tandem-core/services/theming.service";


@Component({
  selector: 'tandem-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup = new FormGroup<any>({});

  public mode: 'login' | 'register' = 'login';

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  preventButtonPress = false;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  constructor(private auth: AuthService,
              private afAuth: AngularFireAuth,
              private router: Router,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private themingService: ThemingService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.themingService.brandingConfig.subscribe(config => {
      if (config.logoURL) {
        this.logoPath = config.logoURL
      }
    })

    this.auth.$user.subscribe(user => {
      if (user) {
        this.router.navigate(['/dashboard'], { queryParamsHandling: 'preserve' });
      }
    })
    this.headerService.resetConfig();
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  onGoogleLogin() {

    if (!this.preventButtonPress) {
      // this.preventButtonPress = true;
      this.auth.onGoogleLogin('login').then(res => {
        this.router.navigate(['/dashboard'])
        this.dialogService.showAlert({title: 'Logged In', content: 'Welcome!', dismissTime: 3000, position: 'bottom'})
      }).catch(err => {
        this.preventButtonPress = false;
      })
    }

    // this.checkUserExists('user@example.com').then(exists => {
    //   if (exists) {
    //   } else {
    //
    //   }
    // }).catch(error => {
    //   console.error('Error checking for user', error);
    // });
  }
  public keydownSignInWithEmail(event: KeyboardEvent) {
    if(event.code === 'Enter') {
      this.signInWithEmail();
    }
  }

  public signInWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithEmailPass(
        this.loginForm.get('email')?.value,
        this.loginForm.get('password')?.value
      ).then(res => {
        this.preventButtonPress = false;
      }).catch(err => {
        this.preventButtonPress = false;
      })
    }
  }

    protected readonly environment = environment;

  forgotPassword() {
    this.dialogService.openModal(ForgotPasswordDialogComponent, {});
  }

  routeWithParams(s: string) {
    this.router.navigate([s], {
      queryParamsHandling: 'preserve'
    })
  }

  checkUserExists(email: string): Promise<boolean> {
    return this.afAuth.fetchSignInMethodsForEmail(email)
      .then(signInMethods => {
        // If the signInMethods array is not empty, a user exists with the given email
        return signInMethods.length > 0;
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error checking for user', error);
        throw error;
      });
  }
}
