import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {TableConfiguration} from "../../../models/table-configuration";
import {CurrencyService} from "../../../services/currency.service";
import {DialogService} from "../../../services/dialog.service";
import {Overlay} from "@angular/cdk/overlay";
import {ModalRef} from "../../../models/modal-ref";
import {StatementItem} from "../../../models/statements/statement-item";
import {StatementItemDialogComponent} from "../statement-item-dialog/statement-item-dialog.component";
import {StatementCategory} from "../../../models/statements/statement-category";
import {DateService} from "../../../services/date.service";
import {Timestamp} from "firebase/firestore";
import {Chat, CommentPanelComponent} from "../../chat/comment-panel/comment-panel.component";
import {Subscription} from "rxjs";
import {AuthService} from "../../../../auth/services/auth.service";
import {StatementSection} from "../../../models/statements/statement-section";

@Component({
  selector: 'tandem-statement-item-table',
  templateUrl: './statement-item-table.component.html',
  styleUrls: ['./statement-item-table.component.scss']
})
export class StatementItemTableComponent implements OnInit {

  @Input() statementItemSection: StatementSection = {name: 'Statement Items', categories: [], description: ''};
  @Input() statementItemCategory: StatementCategory = {name: 'Statement Items', items: [], total: 0};
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Input() displayDate: boolean = false;
  @Input() displayMoveOption: boolean = false;
  @Input() categoryType: 'revenue' | 'expense' = 'revenue';

  @ViewChild('inputField')
  inputField!: ElementRef;

  tableConfig: TableConfiguration<StatementItem> | undefined;
  tableData: StatementItem[] = [];

  userId: string | null = null;
  private eventSubscription?: Subscription;

  @Output() onComment: EventEmitter<{ statementSection: StatementSection, statementCategory: StatementCategory, statementItem: StatementItem, comment: Chat }> = new EventEmitter<{statementSection: StatementSection, statementCategory: StatementCategory; statementItem: StatementItem, comment: Chat}>();

  @Output() changesMade: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemMoved: EventEmitter<StatementItem> = new EventEmitter<StatementItem>();

  constructor(private currencyService: CurrencyService, private auth: AuthService, private overlay: Overlay, private cdr: ChangeDetectorRef, private dateService: DateService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      this.userId = user?.uid || null;
    })
    this.tableConfig = {
      addFunction: () => this.openStatementItemDialog(),
      enableCommenting: true,
      canBeHidden: false,
      columns: [
        {
          title: 'Name',
          itemProperty: 'name',
          columnType: 'text',
          sortable: true
        },
        {
          title: 'Value',
          itemProperty: 'value',
          columnType: 'value',
          sortable: true,
          displayMask: (t: StatementItem) => {return this.currencyService.formatNumber(t.value)}
        }
      ],
      deleteRowFunction: (statementItem: StatementItem) => this.confirmDelete(statementItem),
      editRowFunction: (statementItem: StatementItem) => this.openStatementItemDialog(statementItem),
      moveRowFunction: this.mode === 'create' && this.displayMoveOption ? (statementItem: StatementItem) => this.moveToUncategorized(statementItem) : undefined,
      onRowClick: this.mode == 'view' ? undefined : (statementItem: StatementItem) => this.openStatementItemDialog(statementItem),
      noDataMessage: `No ${this.statementItemCategory.name}`,
      subtitle: this.statementItemCategory.name,
      hoverHint: this.statementItemCategory.hoverHint,
      totalProp: 'value',
      collapsible: true,
      collapsed: false,
      draggable: this.mode !== 'view'
    };
    if (this.displayDate) {
      this.tableConfig.columns.push(
        {
          title: 'Date',
          itemProperty: 'date',
          columnType: 'text',
          sortable: true,
          displayMask: (t: StatementItem) => {
            return t.date ? this.formatDate(Timestamp.fromMillis(1000 * (t.date.seconds || 0)).toDate()) : ''
          }
        })
    }
    if (this.mode === 'view') {
      this.tableConfig.addFunction = undefined;
      this.tableConfig.editRowFunction = undefined;
      this.tableConfig.deleteRowFunction = undefined;
      this.tableConfig.commentRowFunction = (row) => this.openComments(row)
    }
    this.tableData = this.statementItemCategory.items;

    this.eventSubscription = this.dialogService.componentEvents$.subscribe(
      event => this.handleChatEvent(event)
    );
  }

  public openStatementItemDialog(inputStatementItem?: StatementItem): void {
    const ref: ModalRef = this.dialogService.openModal(StatementItemDialogComponent, {
      inputStatementItem: inputStatementItem,
      inputTitle: `${!inputStatementItem ? 'Add' : 'Edit'} ${this.statementItemCategory.name}`,
      requireDate: this.displayDate
    });
    ref.afterClosed().subscribe(statementItem => {
      if (statementItem) {
        if (inputStatementItem) {
          let i = this.statementItemCategory.items.indexOf(inputStatementItem);
          this.statementItemCategory.items[i] = statementItem;
        } else {
          this.statementItemCategory.items.push(statementItem);
        }
        this.tableData = [...this.statementItemCategory.items];
        this.statementItemCategory.items = [...this.statementItemCategory.items];
        this.cdr.detectChanges();
        this.changesMade.emit();
        this.inputField.nativeElement.focus();
      }
    })
  }

  public moveToUncategorized(item: StatementItem) {
    this.itemMoved.emit(item);
  }

  public confirmDelete(item: StatementItem) {
    this.dialogService
      .openConfirmDialog('Delete Item', `Are you sure you want to delete ${item.name}?`, 'Delete')
      .afterClosed().subscribe(confirmed => {
        if (confirmed) {
          let index: number = this.statementItemCategory.items.indexOf(item);
          this.statementItemCategory.items.splice(index, 1);
          this.statementItemCategory.items = [...this.statementItemCategory.items]
          this.cdr.detectChanges();
          this.changesMade.emit();
        }
    })
  }

  private formatDate(date: Date) {
    let day: string | number = date.getUTCDate();
    let month: string | number = date.getUTCMonth() + 1; // January is 0 in UTC as well
    let year = date.getUTCFullYear();

    // ensure double digits
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return month + '/' + day + '/' + year;
  }

  openComments(item: StatementItem) {
    const chatData = {
      chats: item.comments ? item.comments : [],
      chatType: this.categoryType,
      revenueSection: this.categoryType === 'revenue' ? this.statementItemSection : undefined,
      expenseSection: this.categoryType === 'expense' ? this.statementItemSection : undefined,
      statementItem: item,
      statementCategory: this.statementItemCategory,
    };
    this.dialogService.openSidePanel(CommentPanelComponent, chatData);
  }

  private handleChatEvent(event: any) {
    if (event.type === 'statementItemMessage') {
      const chat: Chat = {
        message: event.data,
        date: Timestamp.now(),
        status: 'Delivered',
        userId: this.userId || ''
      };

      this.onComment.emit({
        statementSection: event.statementSection,
        statementItem: event.statementItem,
        comment: chat,
        statementCategory: this.statementItemCategory
      });
    }
  }
}
