import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../user.service";
import {AuthService} from "../../services/auth.service";
import {TandemUser} from "../../models/tandem-user";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'tandem-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit {

  informationForm: FormGroup = new FormGroup<any>({})
  coachInfoForm: FormGroup = new FormGroup<any>({})
  user: TandemUser | null = null;
  constructor(private auth: AuthService,
              private fb: FormBuilder,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }
  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      this.user = user;
    })
    this.informationForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
    });
    this.coachInfoForm = this.fb.group({
      coachBusinessName: [null, Validators.required],
    });
  }

  submit() {
    if (this.user && this.informationForm.valid && (this.user.accountType === 'user' || this.coachInfoForm.valid)) {
      let data = {...this.informationForm.value};
      if (this.user.accountType === 'coach') {
        data = {...this.coachInfoForm.value, ...data}
      }
      this.auth.submitAdditionalInfo(data)
    }


    // if (this.user) {
    //
    //   this.userService.updateUserName(
    //     this.user.uid,
    //     this.informationForm.get('firstName')?.value,
    //     this.informationForm.get('lastName')?.value,
    //     this.informationForm.get('phoneNumber')?.value || undefined,
    //   ).then(res => {
    //     // @ts-ignore
    //     const currentUrl = this.activatedRoute.snapshot['_routerState'].url;
    //     // Check if not on Dashboard route
    //     if (currentUrl !== '/dashboard') {
    //       this.router.navigate(['/dashboard']);
    //     }
    //   })
    // }
  }

  protected readonly environment = environment;
}
