<div class="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img [src]="environment.primaryIconPath" class="mx-auto h-40 w-auto">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">{{ mode === 'resetPassword' && newPassForm ? 'Reset Password' : errors ? 'An Error Occurred' : isLoaded ? 'Successfully Verified Email' : 'Please Wait'}}</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
      <ng-container *ngIf="mode === 'verifyEmail'">
        <div class="w-full h-full flex flex-row items-center justify-center">
          <tandem-loading-container [isLoaded]="isLoaded">
            <div class="w-full">
              <h4 class="text-lg" *ngIf="!errors">Thanks for verifying! Your account is all set up now. Feel free to close this tab and return to where you signed up from!</h4>
              <h4 *ngIf="errors" class="text-red text-lg">Oh no! {{errors}}</h4>
            </div>
          </tandem-loading-container>
        </div>

      </ng-container>
      <ng-container *ngIf="mode === 'resetPassword' && newPassForm">
        <form class="space-y-6">
          <tandem-form-input class="mt-40"
                             [inputType]="'password'"
                             [form]="newPassForm"
                             [controlName]="'newPassword'"
                             [placeholder]="'New Password'"
                             [label]="'New Password'"
                             [inputId]="'newPassword'"></tandem-form-input>
          <div>
            <tandem-button theme="solid" color="primary"  [disabled]="newPassForm.invalid" [onClick]="saveNewPassword.bind(this)">Save</tandem-button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
