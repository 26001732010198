import {EventEmitter, Injectable, Input, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

export interface HeaderConfig {
  title?: string,
  titleRow2?: string,
  subtitle?: string,
  breadcrumbs?: {label: string | undefined, route?: string}[],
  isDashboard?: boolean
  hideHeader?: boolean
  optionalTemplate?: TemplateRef<any>;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private $headerConfig: BehaviorSubject<HeaderConfig | undefined> = new BehaviorSubject<HeaderConfig | undefined>(undefined);
  public visionChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public getConfig(): Observable<HeaderConfig | undefined> {
    return this.$headerConfig;
  }

  public setConfig(config?: HeaderConfig): void {
    this.$headerConfig.next(config);
  }

  public setTitle(title: string): void {
    this.$headerConfig.next({...this.$headerConfig.value, title: title,});
  }

  public setTitleRow2(titleRow2: string): void {
    this.$headerConfig.next({...this.$headerConfig.value, titleRow2: titleRow2,});
  }

  public setSubtitle(subtitle: string): void {
    this.$headerConfig.next({...this.$headerConfig.value, subtitle: subtitle});
  }

  public setBreadcrumbs(breadcrumbs: {label: string, route?: string}[]): void {
    this.$headerConfig.next({...this.$headerConfig.value, breadcrumbs: breadcrumbs});
  }

  public setIsDashboard(isDashboard: boolean): void {
    this.$headerConfig.next({...this.$headerConfig.value, isDashboard: isDashboard});
  }

  public setHideHeader(hideHeader: boolean): void {
    this.$headerConfig.next({...this.$headerConfig.value, hideHeader: hideHeader});
  }

  public setTemplateRef(template: TemplateRef<any>): void {
    this.$headerConfig.next({...this.$headerConfig.value, optionalTemplate: template});
  }

  public resetConfig() {
    this.$headerConfig.next({});
  }
}
