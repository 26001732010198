import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {TandemUser} from "../../modules/auth/models/tandem-user";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../modules/auth/services/auth.service";
import {DialogService} from "../../modules/tandem-core/services/dialog.service";

@Component({
  selector: 'tandem-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() user: TandemUser | null = null;
  @Input() displayHeader: boolean = false;

  protected readonly environment = environment;
  displayUserDropdown = false;
  @Output() sidenavOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private auth: AuthService, private dialogService: DialogService, private _elementRef: ElementRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  toggleUserDropdown() {
    this.displayUserDropdown = !this.displayUserDropdown;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this._elementRef.nativeElement.querySelector('.absolute.top-full') && this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]')) {
      const clickedInsideDropdown = this._elementRef.nativeElement.querySelector('.absolute.top-full').contains(event.target);
      const clickedOnIcon = this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]').contains(event.target);

      if (!clickedInsideDropdown && !clickedOnIcon) {
        this.displayUserDropdown = false;
      }
    }
  }

  onLogout() {
    this.dialogService.openConfirmDialog('Logout', 'Are you sure you want to logout?', 'Logout', 'Cancel').afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.auth.onLogout();
      }
    })
  }

  openSidenav() {
    this.sidenavOpened.emit(true);
    // this.changeDetectorRef.detectChanges();
  }
}
