import { Component } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {DialogService, TandemDialogConfig} from "../../../tandem-core/services/dialog.service";

@Component({
  selector: 'tandem-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent {

  constructor(private fb: FormBuilder,
              private dialog: DialogService) {
  }

  changeOptions() {

  }

  loggy() {
  }

  openDialog(type: string) {

    let config: TandemDialogConfig | null = null;
    switch (type) {
      case 'confirm':
        config = {
          type: 'confirm',
          title: 'Please Confirm',
          content: `We need you to confirm this action. It can't be undone, bitch.`,
          actions: [
            {
              text: 'Cancel',
              role: 'cancel',
              callback: () => {
                this.dialog.closeModal2();
              }
            },
            {
              text: 'Sign Out',
              role: 'confirm',
              callback: () => {
                this.dialog.closeModal2();
              }
            }
          ]
        }
        break;
      case 'loading':
        config = {
          type: 'loading',
          title: 'Sit Tight',
          content: `We're putting in the work for you bro, trust us and hang on.`,
          actions: []
        }
        break;
      case 'success':
        config = {
          type: 'success',
          title: 'Good Job',
          content: `Wow, you did it! That's a surprise. Just hope your luck doesn't run out at some point...`,
          actions: [
            {
              text: 'Close',
              role: 'confirm',
              callback: () => {
                this.dialog.closeModal2();
              }
            }
          ]
        }
        break;
      case 'failure':
        config = {
          type: 'failure',
          title: `Couldn't Sign Up`,
          content: `Whoops, looks like something went wrong on our end. You can try again, or contact customer support.`,
          actions: [
            {
              text: 'Close',
              role: 'close',
              callback: () => {
                this.dialog.closeModal2();
              }
            },
            {
              text: 'Contact Support',
              role: 'contact',
              callback: () => {
                this.dialog.closeModal2();
              }
            }
          ]
        }
        break;
      case 'info':
        config = {
          type: 'info',
          title: 'Your Silly Message',
          content: `Here's your stupid message. Just click the close button you dumb monkey. Out of my sight.`,
          actions: [
            {
              text: 'Close',
              role: 'confirm',
              callback: () => {
                this.dialog.closeModal2();
              }
            }
          ]
        }
        break;
    }
    if (config) {
      this.dialog.openModal2(config);
    }
  }
}
