import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import {
  PageModel,
  TableColumn,
  TableConfiguration,
} from '../../models/table-configuration';
import {CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CoreService} from "../../services/core.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FormArray, FormControl} from "@angular/forms";
import {TandemUser} from "../../../auth/models/tandem-user";

@Component({
  selector: 'tandem-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('expanded <=> collapsed', animate('300ms ease-out'))
    ])
    ],
})
export class TableComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() tableConfiguration: TableConfiguration<T> | null = null;
  // @Input() tableConfiguration: TableConfiguration<T> = {
  //   rowActions: [],
  //   columns: [],
  //   noDataMessage: 'No results found',
  // };
  @Input() data: T[] = [];
  @Input() tableClass?: string;
  @Input() title?: string | undefined = undefined;
  @Input() tableId?: string;
  @Input() moveOptions: string[] = [];
  @Input() filterTemplate?: TemplateRef<any>;

  @Input() resetSelectionsEmitter: EventEmitter<void> = new EventEmitter<void>();
  private resetSub?: Subscription;

  total: number = 0;
  hasMeatball = false;

  selectedItemCount: number | undefined;
  selectedPageIndex: number | undefined;
  connectedDropLists: string[] = [];
  isDraggingItem: boolean = false;

  selectedData: T[] = [];

  massCheckControl: FormControl = new FormControl(false)
  massCheckSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  massCheckObservable$ = this.massCheckSubject.asObservable();
  moveDropdownVisible = false;

  numberOfPages: number = 0;
  currentPageIndex: number = 0;
  pageSize: number = 10; // Default page size, adjust as needed
  pagedData: T[] = [];
  sortedColumn?: keyof T;
  sortDirection: 'asc' | 'desc' = 'asc';

  @ViewChild('dropdownMenu', {read: ElementRef}) dropdownMenu: ElementRef | undefined;

  @Output() onPageModelUpdate: EventEmitter<PageModel<T>> = new EventEmitter<PageModel<T>>();
  @Output() selectedDataChanged: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() selectedItemsMoved: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  @Output() selectedItemsDeleted: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  @Output() onAccessChanged: EventEmitter<{ approved: boolean, user: T }> = new EventEmitter<{ approved: boolean, user: T }>();


  rowCheckControls: FormArray = new FormArray<any>([]);
  clickedRow?: T;
  visiblePages: number[] = [];


  constructor(private coreService: CoreService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.hasMeatball = !!this.tableConfiguration?.editRowFunction || !!this.tableConfiguration?.deleteRowFunction;
    if (this.tableConfiguration?.pageSizeOptions) {
      this.selectedItemCount = this.tableConfiguration?.pageSizeOptions[0];
    }
    if (this.tableConfiguration?.draggable) {
      if (this.tableId) {
        this.coreService.registerDropList(this.tableId)
      }
      this.coreService.isDragging.subscribe(isDragging => {
        this.isDraggingItem = isDragging;
      });

      this.coreService.dropLists.subscribe(lists => {
        // Filter out the current tableId to avoid connecting to itself
        this.connectedDropLists = lists.filter(id => id !== this.tableId);
      });
      this.coreService.dataChange.subscribe(changed => {
        this.data = [...this.data];
      })
    }

    this.massCheckControl.valueChanges.subscribe(newVal => {
      const selectedCount = this.rowCheckControls.controls.filter(ctrl => ctrl.value === true).length;

      if (newVal) {
        if (selectedCount !== this.rowCheckControls.length) {
          this.rowCheckControls.controls.forEach(ctrl => ctrl.setValue(true))
        }
      } else {
        if (selectedCount !== 0) {
          this.rowCheckControls.controls.forEach(ctrl => ctrl.setValue(false))
        }
      };
    })
    this.currentPageIndex = this.tableConfiguration?.pageIndex || 0;
    this.pageSize = this.tableConfiguration?.pageSize || 10;
    this.sortedColumn = this.tableConfiguration?.sortColumn;
    this.sortDirection = this.tableConfiguration?.sortDirection || 'asc';
    if (this.tableConfiguration?.paginated) {
      this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
    }
    this.calculateVisiblePages();

    this.resetSub = this.resetSelectionsEmitter.subscribe(() => {this.resetSelection()});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableConfiguration?.totalProp) {
      let total = 0;
      this.data.forEach(d => {
        // @ts-ignore
        let num: number = d[this.tableConfiguration!.totalProp] as number;
        total += num;
      });
      this.total = total;
    }

    if (changes['data'].currentValue) {
      this.data = [];
      this.rowCheckControls = new FormArray<any>([]);
      this.rowCheckControls.controls = [];
      this.data = [...changes['data'].currentValue]
      this.selectedData = [];
      this.rowCheckControls.controls = [...this.data.map(t => new FormControl(false))];
      this.massCheckControl.setValue(false);
      this.numberOfPages = Math.ceil(this.data.length / this.pageSize);
      if (this.tableConfiguration?.paginated) {
        this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
        this.calculateVisiblePages();
      }
    }
  }

  ngOnDestroy() {
    this.resetSub?.unsubscribe();
  }

  performAction(row: T) {
    if (!!this.tableConfiguration?.onRowClick) {
      this.tableConfiguration.onRowClick(row);
    }
  }

  drop(event: CdkDragDrop<T[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.data, event.previousIndex, event.currentIndex);
      // Manually trigger the update
      this.data = JSON.parse(JSON.stringify(this.data));
      this.coreService.dataChange.next();
    } else {
      // Perform the transfer manually
      const item = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data.splice(event.previousIndex, 1);
      event.container.data.splice(event.currentIndex, 0, item);

      // Manually trigger the update for both containers
      if (this.tableId === event.previousContainer.id) {
        this.data = [...event.previousContainer.data];
      } else if (this.tableId === event.container.id) {
        this.data = [...event.container.data];
      }

      // If you need to update the parent component or sibling components,
      // emit an event or call a method to propagate the changes
      this.notifyDataChange();
    }
  }

  notifyDataChange(): void {
    // Emit an event or call a method to update the parent component
    this.coreService.dataChange.next();
  }

  onDragStart(event: CdkDragStart) {
    this.coreService.startDrag();
  }

  onDragEnd(event: CdkDragEnd) {
    this.coreService.stopDrag();
  }

  onTrigger(row: T, type: 'delete' | 'download' | 'edit' | 'move' | 'comment') {
    if (type === 'delete' && this.tableConfiguration?.deleteRowFunction) {
      this.tableConfiguration.deleteRowFunction(row);
    }
    if (type === 'edit' && this.tableConfiguration?.editRowFunction) {
      this.tableConfiguration.editRowFunction(row);
    }
    if (type === 'comment' && this.tableConfiguration?.commentRowFunction) {
      this.tableConfiguration.commentRowFunction(row);
    }
    if (type === 'download' && this.tableConfiguration?.downloadRowFunction) {
      this.tableConfiguration.downloadRowFunction(row);
    }
    if (type === 'move' && this.tableConfiguration?.moveRowFunction) {
      this.tableConfiguration.moveRowFunction(row);
    }
    this.closeDropdown();
    this.coreService.dataChange.next();
  }

  getDisplayForElement(row: T, col: TableColumn<T>): string {
    if (!!col.displayMask) {
      return col.displayMask(row);
    } else {
      return String(row[col.itemProperty]);
    }
  }

  onAdd() {
    if (!!this.tableConfiguration?.addFunction) {
      this.tableConfiguration.addFunction();
    }
  }

  getStatusHex(status: any) {
    switch (status) {
      case 'Active':
        return '#56E07D';
      case 'Paused':
        return '#2B56F0';
      case 'Pending':
        return '#636C7A';
    }
    return '';
  }

  onSort(sortDirection: 'asc' | 'desc' | undefined, sortedCol: TableColumn<T>) {
    if (sortedCol.sortable) {
      this.tableConfiguration?.columns.forEach(column => {
        if (this.tableConfiguration?.columns.indexOf(column) !== this.tableConfiguration?.columns.indexOf(sortedCol)) {
          column.sorted = false;
        }
      });
      sortedCol.sorted = true;
    }
    this.onPageModelUpdate.emit({
      count: this.selectedItemCount,
      offset: (this.selectedItemCount && this.selectedPageIndex) ? this.selectedItemCount * this.selectedPageIndex : undefined,
        // TODO do this: lastDocument: null
      sortOrder: sortDirection || undefined,
      sortProperty: sortedCol.itemProperty || undefined,
      pageNumber: this.selectedPageIndex,
    })
  }

  toggleCollapse() {
    if (this.tableConfiguration?.collapsible) {
      this.tableConfiguration.collapsed = !this.tableConfiguration.collapsed;
    }
  }

  shouldDisplayTotal(): boolean {
    return !!this.tableConfiguration?.totalProp &&
      (this.tableConfiguration.collapsed || this.total > 0);
  }


  onCheckboxToggle($event: boolean, row: T) {
    if ($event) {
      if (!this.selectedData.includes(row)) {
        this.selectedData.push(row);
      }
    } else {
      let i = this.selectedData.indexOf(row);
      if (i !== -1) {
        this.selectedData.splice(i, 1);
      }
    }
    this.selectedDataChanged.emit(this.selectedData);
    const selectedCount = this.rowCheckControls.controls.filter(ctrl => ctrl.value === true).length;

    if (selectedCount === 0 && this.massCheckControl.value) {
      this.massCheckControl.setValue(false);
    } else if (selectedCount !== 0 && selectedCount === this.rowCheckControls.length && !this.massCheckControl.value) {
      this.massCheckControl.setValue(true);
    }
    ;

  }

  massToggle($event: boolean) {
    this.massCheckSubject.next($event);
  }

  onMove(option: string) {
    this.moveDropdownVisible = false;
    this.selectedItemsMoved.next(option);
    // this.massCheckControl.setValue(false);
  }

  onDelete() {
    this.moveDropdownVisible = false;
    this.selectedItemsDeleted.next(undefined);
    this.massCheckControl.setValue(false);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.moveDropdownVisible = false;
    }
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation(); // Prevent click event from propagating to the document
    this.moveDropdownVisible = !this.moveDropdownVisible;
  }

  protected readonly event = event;

  toggleRowDropdown(row: T) {
    if (!this.clickedRow) {
      this.clickedRow = row;
    } else if (this.clickedRow !== row) {
      this.clickedRow = row;
    } else {
      this.clickedRow = undefined;
    }
  }

  closeDropdown() {
    this.clickedRow = undefined;
  }

  getControlForRow(i: number) {
    return this.rowCheckControls.controls[i] as FormControl<boolean>;
  }

  isIndeterminate() {
    const selectedCount = this.rowCheckControls.controls.filter(ctrl => ctrl.value === true).length;
    return selectedCount !== 0 && selectedCount !== this.rowCheckControls.controls.length;
  }

  getImageAltInitials(row: T, col: TableColumn<T>) {

    let displayName = String(row[col.itemProperty]);

    // TODO is this dumb?
    let initials = `${displayName.split(' ')[0]?.at(0)?.toUpperCase()}${displayName.split(' ')[1]?.at(0)?.toUpperCase()}`
    return !!initials ? initials : 'N/A';
  }

  calculateVisiblePages(): void {
    this.visiblePages = [];
    const currentPage = this.currentPageIndex;
    const totalPages = this.numberOfPages;

    // Define how many pages you want to show before and after the current page
    const pagesToShow = 2;

    for (let i = Math.max(currentPage - pagesToShow, 0); i <= Math.min(currentPage + pagesToShow, totalPages - 1); i++) {
      this.visiblePages.push(i);
    }
  }

  incrementPage() {
    if (this.currentPageIndex !== this.numberOfPages - 1) {
      this.currentPageIndex += 1;
      this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
      this.calculateVisiblePages();
    }
  }

  decrementPage() {
    if (this.currentPageIndex !== 0) {
      this.currentPageIndex -= 1;
      this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
      this.calculateVisiblePages();
    }
  }

  setPage(page: number) {
    if (0 <= page && page < this.numberOfPages) {
      this.currentPageIndex = page;
      this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
      this.calculateVisiblePages();
    }
  }

  toggleSort(col: TableColumn<T>) {
    if (this.tableConfiguration) {
      if (this.tableConfiguration.sortColumn === col.itemProperty) {
        this.tableConfiguration.sortDirection = this.tableConfiguration.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.tableConfiguration.sortColumn = col.itemProperty;
        this.tableConfiguration.sortDirection = 'asc';
      }
      this.pagedData = this.data.sort((a, b) => {
        let valueA = a[this.tableConfiguration!.sortColumn!];
        let valueB = b[this.tableConfiguration!.sortColumn!];

        // Determine if ascending or descending
        if (this.tableConfiguration!.sortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });
      if (this.tableConfiguration?.paginated) {
        this.pagedData = this.data.slice(this.currentPageIndex * this.pageSize, Math.min((this.currentPageIndex * this.pageSize) + this.pageSize, this.data.length))
        this.setPage(0)
      }
    }
  }

  manageAccess(row: T, b: boolean) {
    this.onAccessChanged.emit({approved: b, user: row});
  }

  displayCommentIcon(row: T) {
    // @ts-ignore
    return row['comments']?.length > 0;
  }

  public resetSelection() {
    this.selectedData = [];
    this.rowCheckControls.controls.forEach(control => control.setValue(false));
    this.massCheckControl.setValue(false);
  }

  showFolderIcon(col: TableColumn<T>, row: T) {
    // @ts-ignore
    return col.columnType === 'fileItem' && row['type'] === 'folder';
  }
}
