import {Directive, ElementRef, HostListener, Input, OnDestroy} from "@angular/core";

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy{

  @Input() tooltip = ''; // The text for the tooltip to display
  @Input() delay? = 50; // Optional delay input, in ms
  @Input() maxWidth?: string; // Optional max width for the tooltip

  private myPopup?: HTMLDivElement;
  private timer?: string | number | NodeJS.Timeout | undefined;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove() }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.timer = setTimeout(() => {
      let x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y = this.el.nativeElement.getBoundingClientRect().top - 6; // Get the top of the element, minus a little extra
      this.createTooltipPopup(x, y);
    }, this.delay)
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement('div');
    let message = popup.appendChild(document.createElement('h5'));
    message.innerHTML = this.tooltip;

    popup.classList.add("tooltip-container", "p-9", "rounded-sm");

    // Set max-width if specified
    if (this.maxWidth) {
      popup.style.maxWidth = this.maxWidth;
    }

    popup.style.bottom = (window.innerHeight - y).toString() + "px";  // Adjust based on the new y
    popup.style.left = x.toString() + "px";
    document.body.appendChild(popup);
    this.myPopup = popup;

    setTimeout(() => {
      if (this.myPopup) this.myPopup.remove();
    }, 8000); // Remove tooltip after 8 seconds
  }

}
