<tandem-card *ngIf="tableConfiguration" class="px-20 sm:px-6 lg:px-8 overflow-y-visible"  [ngClass]="tableClass" [isDashedBorder]="tableConfiguration.dashedBorder || false">
  <div *ngIf="tableConfiguration.title || tableConfiguration.subtitle" class="flex flex-row justify-between items-center pt-3">

    <div class="w-full flex flex-row justify-between items-center">
      <div class="sm:flex-auto pr-3">
        <h1 class="text-2xl font-semibold leading-6 text-gray-900" [ngClass]="{'text-primary': tableConfiguration.primaryTitle}">{{ tableConfiguration.title }}</h1>
        <div class="flex items-center gap-3">
          <p class="text-xl mt-2 text-gray-700">{{ tableConfiguration.subtitle }}</p>
          <tandem-tooltip *ngIf="tableConfiguration.hoverHint" [text]="tableConfiguration.hoverHint"></tandem-tooltip>
        </div>
      </div>
      <div class="flex flex-row gap-4">
        <div *ngIf="tableConfiguration.addFunction && !tableConfiguration.collapsed" class="sm:ml-16 sm:mt-0 sm:flex-none h-fit w-fit">
          <button type="button" class="inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary h-fit w-fit whitespace-nowrap" style="height: max-content" (click)="tableConfiguration.addFunction()">Add New</button>
        </div>
        <div *ngIf="tableConfiguration.collapsible" class="flex flex-col justify-start h-full hover:cursor-pointer">
          <tandem-icon icon="upload-circle" [width]="35" [height]="30" (click)="toggleCollapse()" [ngClass]="{'rotate-180': !tableConfiguration.collapsed}" color="primary"></tandem-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <ng-container *ngIf="filterTemplate" [ngTemplateOutlet]="filterTemplate"></ng-container>
    <div class="overflow-x-auto overflow-y-visible"  [@expandCollapse]="tableConfiguration.collapsed ? 'collapsed' : 'expanded'">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="relative">
           <div *ngIf="selectedData.length > 0" class="absolute top-0 left-14 flex h-12 items-center space-x-3 bg-white sm:left-12 z-30">
             <div class="relative">
               <button type="button" class="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-md" (click)="$event.stopPropagation(); moveDropdownVisible = true">Move</button>
               <div *ngIf="moveDropdownVisible" (clickOutside)="moveDropdownVisible = false" class="absolute left-0 z-50 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                 <!-- Active: "bg-gray-50", Not Active: "" -->
                 <a *ngFor="let opt of moveOptions" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-{{opt}}" (click)="onMove(opt)">{{opt}}</a>
               </div>
             </div>
             <button type="button" class="inline-flex items-center rounded px-2 py-1 text-sm font-semibold text-white shadow-sm bg-red-500 hover:bg-red-600 rounded-md" (click)="onDelete()">Delete</button>
           </div>
          <table class="min-w-full divide-y divide-gray-300 relative">
            <thead>
            <tr>
              <th *ngIf="tableConfiguration.hasCheckbox" scope="col" class="relative px-7 sm:w-12 sm:px-6">
                <input [formControl]="massCheckControl" type="checkbox" [indeterminate]="isIndeterminate()" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary">
              </th>
              <th *ngFor="let col of tableConfiguration.columns" scope="col" class="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 flex gap-2" [ngClass]="{'min-w-[6rem]': col.columnType !== 'actions', 'flex flex-row justify-end': col.columnType === 'actions'}">
                <div (click)="toggleSort(col)" class="flex flex-row items-center gap-1">
                  <ng-container *ngIf="tableConfiguration.sortColumn === col.itemProperty">
                    <svg *ngIf="tableConfiguration.sortDirection === 'desc'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg *ngIf="tableConfiguration.sortDirection === 'asc'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>

                  </ng-container>
                  <span class="cursor-pointer">{{col.title}}</span>
                </div>
              </th>
              <th *ngIf="tableConfiguration.editRowFunction || tableConfiguration.moveRowFunction || tableConfiguration.downloadRowFunction || tableConfiguration.deleteRowFunction || tableConfiguration.commentRowFunction" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span class="sr-only">Actions</span>
              </th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white w-full">
            <!-- Selected row background, apply if the row is selected. -->
            <tr *ngFor="let row of tableConfiguration.paginated ? pagedData : data; let i = index" class="w-full group relative" (click)="performAction(row)" [ngClass]="{'hover:cursor-pointer hover:bg-gray-50': this.tableConfiguration.onRowClick}">
              <td *ngIf="tableConfiguration.hasCheckbox" class="relative px-7 sm:w-12 sm:px-6">
                <!-- Selected row marker, only show when row is selected. -->
<!--                 <div class="absolute inset-y-0 left-0 w-0.5 bg-primary"></div>-->
                <tandem-form-checkbox [control]="getControlForRow(i)" (checkboxToggled)="onCheckboxToggle($event, row)" class="absolute left-4 top-1/2 -mt-2 h-4 w-4"></tandem-form-checkbox>
              </td>
              <!-- Selected: "text-primary", Not Selected: "text-gray-900" -->
              <!-- do we add font-medium here? -->
              <td *ngFor="let col of tableConfiguration.columns" class="whitespace-nowrap pr-3 py-4 text-sm text-gray-900">
                <ng-container *ngIf="col.getImageURL">
                  <div class="flex items-center">
                    <div class="h-11 w-11 flex-shrink-0">
                      <img *ngIf="col.getImageURL(row)" class="h-11 w-11 rounded-full" [src]="col.getImageURL(row)" [alt]="getDisplayForElement(row, col)">
                      <span *ngIf="!col.getImageURL(row)" class="inline-flex h-11 w-11 items-center justify-center rounded-full bg-primary">
                        <span class="font-medium leading-none text-white">{{ getImageAltInitials(row, col)}}</span>
                      </span>
                    </div>
                    <div class="ml-4">
                      <div>{{getDisplayForElement(row, col)}}</div>
                    </div>
                  </div>
                </ng-container>
<!--                <img *ngIf="col.getImageURL" class="h-6 w-auto rounded-full" [src]="col.getImageURL(row)" [alt]="getDisplayForElement(row, col)">-->
                <ng-container *ngIf="!col.getImageURL">
                  <ng-container *ngIf="col.columnType === 'fileItem'">
                    <div class="flex flex-row gap-2 items-center">
                      <tandem-icon *ngIf="showFolderIcon(col, row)" [icon]="'folder'" color="black"></tandem-icon>
                      {{getDisplayForElement(row, col)}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.columnType !== 'status' && col.columnType !== 'fileItem'">
                    {{getDisplayForElement(row, col)}}
                  </ng-container>
                  <ng-container *ngIf="col.columnType === 'status'">
                    <div class="w-full flex justify-between">
                      <span>{{getDisplayForElement(row, col)}}</span>
                      <div *ngIf="row[col.itemProperty] === 'Pending'" class="flex gap-4">
                        <div (click)="$event.stopPropagation(); manageAccess(row, true)" class="cursor-pointer flex gap-2 items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          <span>Approve</span>
                        </div>
                        <div (click)="$event.stopPropagation(); manageAccess(row, false)" class="cursor-pointer flex gap-2 items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                        <span>Deny</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </td>
              <td *ngIf="tableConfiguration.editRowFunction || tableConfiguration.moveRowFunction || tableConfiguration.downloadRowFunction || tableConfiguration.deleteRowFunction || tableConfiguration.commentRowFunction"
                  class="whitespace-nowrap py-4 px-0.5 md:px-3 pr-4 text-right text-sm font-medium flex flex-row justify-end relative">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                     class="w-6 h-6 cursor-pointer sm:hidden"
                     [ngClass]="{'hidden': displayCommentIcon(row)}"
                     (click)="toggleRowDropdown(row); $event.stopPropagation()">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>
                <tandem-icon *ngIf="tableConfiguration.commentRowFunction"
                             [icon]="'chat-bubble'"
                             class="mr-2 transition-opacity duration-200"
                             [ngClass]="{
                 'opacity-100': displayCommentIcon(row),
                 'opacity-0 group-hover:opacity-100 absolute': !displayCommentIcon(row)
               }"
                             (click)="onTrigger(row, 'comment'); $event.stopPropagation()">
                </tandem-icon>



                <div *ngIf="row === clickedRow"
                     (clickOutside)="closeDropdown()"
                     class="absolute right-0 mt-5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                     style="z-index: 9999999"
                     role="menu"
                     aria-orientation="vertical"
                     aria-labelledby="user-menu-button"
                     tabindex="-1">
                  <a *ngIf="tableConfiguration.moveRowFunction" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-0" (click)="$event.stopPropagation();onTrigger(row, 'move')">Move</a>
                  <a *ngIf="tableConfiguration.editRowFunction" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-1" (click)="$event.stopPropagation();onTrigger(row, 'edit')">{{tableConfiguration.editText || 'Edit'}}</a>
                  <a *ngIf="tableConfiguration.commentRowFunction" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-2" (click)="$event.stopPropagation();onTrigger(row, 'comment')">Comment</a>
                  <a *ngIf="tableConfiguration.downloadRowFunction" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-3" (click)="$event.stopPropagation();onTrigger(row, 'download')">Download</a>
                  <a *ngIf="tableConfiguration.deleteRowFunction" class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-primary hover:text-white cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-4" (click)="$event.stopPropagation();onTrigger(row, 'delete')">Delete</a>
                </div>
              </td>
            </tr>
            <tr *ngIf="clickedRow" class="w-full h-20"><td [colSpan]="tableConfiguration.columns.length"></td></tr>
            <tr *ngIf="shouldDisplayTotal()">
              <td class="whitespace-nowrap pr-3 py-4 font-semibold">
                Total:
              </td>
              <td class="whitespace-nowrap pr-3 py-4 font-semibold" [colSpan]="tableConfiguration.columns.length">
                {{total | currency}}
              </td>
            </tr>
            <tr *ngIf="data.length === 0" class="w-full py-2">

              <td class="p-3" [colSpan]="tableConfiguration.columns.length + 1">
                No data to display.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h2 *ngIf="tableConfiguration.collapsed && shouldDisplayTotal()" class="mt-4 font-semibold text-lg">Total: {{total | currency}}</h2>
    <ng-container *ngIf="!tableConfiguration.collapsed && data.length > 0 && tableConfiguration.paginated">
      <div class="flex flex-1 justify-between sm:hidden mt-4">
        <a (click)="decrementPage()" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50" [ngClass]="{'opacity-60 cursor-not-allowed': currentPageIndex === 0}">Previous</a>
        <p>{{(currentPageIndex * pageSize) + 1}} - {{(currentPageIndex * pageSize) + pageSize > data.length ? data.length : (currentPageIndex * pageSize) + pageSize}} of {{ data.length || 0 }}</p>
        <a (click)="incrementPage()" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50" [ngClass]="{'opacity-60 cursor-not-allowed': currentPageIndex === numberOfPages - 1}">Next</a>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mt-4">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{(currentPageIndex * pageSize) + 1}}</span>
            to
            <span class="font-medium">{{(currentPageIndex * pageSize) + pageSize > data.length ? data.length : (currentPageIndex * pageSize) + pageSize}}</span>
            of
            <span class="font-medium">{{ data.length || 0 }}</span>
            results
          </p>
        </div>
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a (click)="decrementPage()" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 cursor-pointer hover:bg-gray-50 focus:z-20 focus:outline-offset-0" [ngClass]="{'opacity-60 cursor-not-allowed': currentPageIndex === 0}">
              <span class="sr-only">Previous</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
              </svg>
            </a>
            <a *ngIf="currentPageIndex > 2" (click)="setPage(0)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 cursor-pointer">1</a>
            <span *ngIf="currentPageIndex > 2" class="px-2 py-2">...</span>
            <a *ngFor="let page of visiblePages" (click)="setPage(page)" class="px-4 py-2" [ngClass]="{'bg-primary text-white': currentPageIndex === page, 'cursor-pointer text-gray-900': currentPageIndex !== page}">{{ page + 1 }}</a>
            <span *ngIf="currentPageIndex < numberOfPages - 3" class="px-2 py-2">...</span>
            <a *ngIf="currentPageIndex < numberOfPages - 3" (click)="setPage(numberOfPages - 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 cursor-pointer">{{numberOfPages}}</a>
            <a (click)="incrementPage()" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-600 ring-1 ring-inset ring-gray-300 cursor-pointer hover:bg-gray-50 focus:z-20 focus:outline-offset-0" [ngClass]="{'opacity-60 cursor-not-allowed': currentPageIndex === numberOfPages - 1}">
              <span class="sr-only">Next</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </ng-container>
  </div>
</tandem-card>



<!--<div class="w-full parent-container" *ngIf="tableConfiguration">-->
<!--  <div class="justify-between flex items-start">-->
<!--    <h2 class="mb-15" *ngIf="tableConfiguration.title">{{ tableConfiguration.title }}</h2>-->
<!--    <div class="flex flex-row gap-5">-->
<!--      <h3 class="mb-15" *ngIf="tableConfiguration.subtitle">{{ tableConfiguration.subtitle }}</h3><tandem-tooltip *ngIf="tableConfiguration.hoverHint" [text]="tableConfiguration.hoverHint"></tandem-tooltip>-->
<!--    </div>-->
<!--    &lt;!&ndash;    <button class="text-primary font-medium text-12" *ngIf="tableConfiguration.canBeHidden">Hide</button>&ndash;&gt;-->
<!--    <div *ngIf="tableConfiguration.collapsible" class="flex flex-col justify-start h-full hover:cursor-pointer">-->
<!--      <tandem-icon icon="upload-circle" [width]="35" [height]="30" (click)="toggleCollapse()" [ngClass]="{'rotate-180': !tableConfiguration.collapsed}"></tandem-icon>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div [@expandCollapse]="tableConfiguration.collapsed ? 'collapsed' : 'expanded'">-->
<!--    <table [ngClass]="tableClass" class="table w-full">-->
<!--      <tr-->
<!--        class="flex border-b-[1px] border-dark/20 pb-10 mb-25 items-center justify-between w-full ![&>th:first-child]:justify-start ![&>th:last-child]:justify-end"-->
<!--      >-->
<!--        <head-item *ngFor="let col of tableConfiguration.columns" [sortable]="col.sortable" [sorted]="col.sorted" (onSort)="onSort($event, col)" [sortedByDefault]="!!col.sortedByDefault">{{col.title}}</head-item>-->
<!--        <head-item *ngIf="!!tableConfiguration.editRowFunction || !!tableConfiguration.deleteRowFunction">Actions</head-item>-->
<!--      </tr>-->
<!--      <div class="flex flex-col gap-y-5">-->
<!--        &lt;!&ndash;      <line-item type="draggable" [hasCheckbox]="true">&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;      </line-item>&ndash;&gt;-->
<!--        &lt;!&ndash;      <line-item [hasCheckbox]="true">&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <td>stuff</td>&ndash;&gt;-->
<!--        &lt;!&ndash;      </line-item>&ndash;&gt;-->
<!--        <div *ngIf="tableConfiguration.hasCheckbox" class="flex flex-row justify-start items-center gap-20 mb-20 relative">-->
<!--          <tandem-form-checkbox *ngIf="tableConfiguration.hasCheckbox && data.length > 0" id="mass-select" (checkboxToggled)="massToggle($event)" [control]="massCheckControl" [label]="massCheckControl.value ? 'Deselect All' : 'Select All'" labelPosition="after"></tandem-form-checkbox>-->
<!--          <ng-container *ngIf="selectedData.length > 0">-->
<!--            <div class="relative">-->
<!--              <tandem-button theme="outline" color="primary" class="example-standalone-trigger" (click)="toggleDropdown($event)">Move to</tandem-button>-->
<!--              <div #dropdownMenu *ngIf="moveDropdownVisible" class="example-menu" cdkMenu [ngClass]="{'display-none': !moveDropdownVisible}">-->
<!--                <button *ngFor="let option of moveOptions" (click)="onMove(option)" class="example-menu-item" cdkMenuItem>{{option}}</button>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                #dropdownMenu&ndash;&gt;-->
<!--&lt;!&ndash;                *ngIf="moveDropdownVisible"&ndash;&gt;-->
<!--&lt;!&ndash;                class="absolute top-full right-0 mt-2 flex flex-col gap-5 px-8 py-16 rounded-xs bg-dark/20 z-50 tandem-dropdown shadow-lg w-fit"&ndash;&gt;-->
<!--&lt;!&ndash;                (click)="$event.stopPropagation()"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <h5 *ngFor="let option of moveOptions" class="hover:cursor-pointer" (click)="onMove(option)">{{option}}</h5>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <tandem-button theme="outline" color="red" [onClick]="onDelete.bind(this)">Delete</tandem-button>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--        <ng-container *ngIf="tableConfiguration.draggable">-->
<!--          <div cdkDropListGroup>-->
<!--            <div cdkDropList [cdkDropListData]="data" [id]="tableId || 'noId'" [cdkDropListConnectedTo]="connectedDropLists" (cdkDropListDropped)="drop($event)" class="drop-list-placeholder" [class.dragging-over]="isDraggingItem">-->
<!--              <line-item-->
<!--                class="example-box"-->
<!--                [hasCheckbox]="tableConfiguration.hasCheckbox"-->
<!--                [massCheckObservable]="massCheckObservable$"-->
<!--                *ngFor="let row of data"-->
<!--                cdkDrag-->
<!--                [hasMeatball]="hasMeatball"-->
<!--                [ngClass]="{'hover:cursor-pointer hover:bg-primary/10': !!tableConfiguration.onRowClick}"-->
<!--                (click)="performAction(row)"-->
<!--                (onEdit)="onTrigger(row, 'edit')"-->
<!--                (onMove)="onTrigger(row, 'move')"-->
<!--                (onDelete)="onTrigger(row, 'delete')"-->
<!--                [type]="tableConfiguration.draggable ? 'draggable' : ''"-->
<!--                [showMove]="!!tableConfiguration.moveRowFunction"-->
<!--              (cdkDragStarted)="onDragStart($event)"-->
<!--              (cdkDragEnded)="onDragEnd($event)">-->
<!--                <td *ngFor="let col of tableConfiguration.columns; let isLast = last" class="flex flex-row items-center" [class.justify-end]="isLast && !hasMeatball">-->
<!--                  <img *ngIf="col.getImageURL" [src]="col.getImageURL(row)" referrerpolicy="no-referrer" class="mr-10 h-27 w-27 rounded-full">-->
<!--                  {{getDisplayForElement(row, col)}}-->
<!--                  <svg class="ml-10" *ngIf="col.columnType === 'status' && col.itemProperty" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">-->
<!--                    <circle  cx="5" cy="5" r="5" [attr.fill]="getStatusHex(row[col.itemProperty])"/>-->
<!--                    &lt;!&ndash;            <path *ngIf="row[col.itemProperty] === 'pending'" d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5.5 5V2.5H4.5V6H7.5V5H5.5Z" fill="#636C7A"/>&ndash;&gt;-->
<!--                  </svg>-->
<!--                </td>-->
<!--              </line-item>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!tableConfiguration.draggable">-->
<!--          <line-item-->
<!--            *ngFor="let row of data"-->
<!--            [hasMeatball]="hasMeatball"-->
<!--            [hasCheckbox]="tableConfiguration.hasCheckbox"-->
<!--            [massCheckObservable]="massCheckObservable$"-->
<!--            (onCheckboxToggle)="onCheckboxToggle($event, row)"-->
<!--            [ngClass]="{'hover:cursor-pointer hover:bg-primary/10': !!tableConfiguration.onRowClick}"-->
<!--            (click)="performAction(row)"-->
<!--            (onEdit)="onTrigger(row, 'edit')"-->
<!--            (onDelete)="onTrigger(row, 'delete')"-->
<!--            [type]="tableConfiguration.draggable ? 'draggable' : ''"-->
<!--            [showMove]="!!tableConfiguration.moveRowFunction">-->
<!--            <td *ngFor="let col of tableConfiguration.columns; let isLast = last" class="flex flex-row items-center" [class.justify-end]="isLast && !hasMeatball">-->
<!--              <img *ngIf="col.getImageURL" [src]="col.getImageURL(row)" referrerpolicy="no-referrer" class="mr-10 h-27 w-27 rounded-full">-->
<!--              {{getDisplayForElement(row, col)}}-->
<!--              <svg class="ml-10" *ngIf="col.columnType === 'status' && col.itemProperty" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">-->
<!--                <circle  cx="5" cy="5" r="5" [attr.fill]="getStatusHex(row[col.itemProperty])"/>-->
<!--                &lt;!&ndash;            <path *ngIf="row[col.itemProperty] === 'pending'" d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5.5 5V2.5H4.5V6H7.5V5H5.5Z" fill="#636C7A"/>&ndash;&gt;-->
<!--              </svg>-->
<!--            </td>-->
<!--          </line-item>-->
<!--        </ng-container>-->
<!--        <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="tableConfiguration?.draggable">-->

<!--        </div>-->
<!--        <div *ngIf="data.length === 0" class="flex items-center">-->
<!--          <span class="text-19 font-medium">{{tableConfiguration.noDataMessage}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </table>-->
<!--  </div>-->
<!--  <tandem-pagination *ngIf="tableConfiguration.pageSizeOptions" [pages]="tableConfiguration.pageSizeOptions" />-->
<!--  <add-new-row [total]="total" [displayTotal]="shouldDisplayTotal()" [displayButton]="!!tableConfiguration.addFunction" (onAdd)="onAdd()"/>-->
<!--</div>-->



<!--if 5px gap, use gap-5 and it will convert to rem auto-->

<!--opacity is unique, can't use normal variables. cant use slash notation-->
