<!--<ng-container *ngIf="!!control && !!inputType">-->
<!--  <div class="inline-flex flex-col items-start gap-5 w-full">-->
<!--    <p class="w-full">{{customLabel ? customLabel : label}}</p>-->
<!--    <input-->
<!--      *ngIf="inputType === 'number'"-->
<!--       [formControl]="control"-->
<!--       [id]="inputId"-->
<!--      type="number"-->
<!--       [placeholder]="placeholder"-->
<!--      (focus)="onFocus()"-->
<!--       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>-->
<!--    <div class="sm:col-span-4">-->
<!--      <label [for]="inputId" class="block text-sm font-medium leading-6 text-gray-900">{{customLabel ? customLabel : label}}</label>-->
<!--      <div class="mt-2">-->
<!--        <input-->
<!--          [id]="inputId"-->
<!--          [name]="inputId"-->
<!--          [type]="inputType"-->
<!--          autocomplete="email"-->
<!--          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">-->
<!--      </div>-->
<!--    </div>-->
<!--&lt;!&ndash;    <input&ndash;&gt;-->
<!--&lt;!&ndash;      *ngIf="inputType !== 'number' && inputType !== 'date'"&ndash;&gt;-->
<!--&lt;!&ndash;       [formControl]="control"&ndash;&gt;-->
<!--&lt;!&ndash;       [id]="inputId"&ndash;&gt;-->
<!--&lt;!&ndash;       [type]="inputType"&ndash;&gt;-->
<!--&lt;!&ndash;       [placeholder]="placeholder"&ndash;&gt;-->
<!--&lt;!&ndash;      (focus)="onFocus()"&ndash;&gt;-->
<!--&lt;!&ndash;       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>&ndash;&gt;-->
<!--    <input-->
<!--      *ngIf="inputType === 'date'"-->
<!--       [formControl]="control"-->
<!--       [id]="inputId"-->
<!--       type="date"-->
<!--       [placeholder]="placeholder"-->
<!--      (focus)="onFocus()"-->
<!--       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>-->
<!--    <div *ngIf="control.invalid && (control.dirty || control.touched) && !controlBlurred" class="text-12 text-red">-->
<!--      <div *ngIf="control.hasError('required')">{{label}} is required</div>-->
<!--      <div *ngIf="control.hasError('minLength')">{{label}} doesn't have enough characters</div>-->
<!--      <div *ngIf="control.hasError('maxLength')">{{label}} has too many characters</div>-->
<!--      <div *ngIf="control.hasError('max')">{{getMaxMessage()}}</div>-->
<!--      <div *ngIf="control.hasError('email')">Invalid email</div>-->
<!--      <div *ngIf="control.hasError('minLength')">{{getMinMessage()}}</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-container>-->

<div *ngIf="control" class="mb-5">
  <div class="flex justify-between">
    <div class="flex gap-2">
      <label [for]="inputId" class="block text-sm font-medium leading-6 text-gray-900">{{customLabel ? customLabel : label}}</label>
      <tandem-tooltip *ngIf="tooltipText" [text]="tooltipText"></tandem-tooltip>
    </div>
    <span *ngIf="isOptional" class="text-sm leading-6 text-gray-500 pr-2 italic" id="email-optional">Optional</span>
  </div>
  <div class="relative mt-2 rounded-md shadow-sm">
    <div *ngIf="prefixText" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <span class="text-gray-500 sm:text-sm">{{ prefixText }}</span>
    </div>
    <input #input [formControl]="control" [type]="inputType" [name]="inputId" [id]="inputId"
           [ngClass]="{
             'pl-7': prefixText,
             'pr-16': suffixText && control.touched && control.invalid,
             'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500': control.touched && control.invalid
           }"
           class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-primary sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
           [placeholder]="placeholder" [attr.aria-describedby]="inputId"
           [attr.aria-invalid]="(control.touched && control.invalid) ? true : null"
           (focus)="onFocus()">
    <!-- Adjust the right padding on the suffix container based on the error state -->
    <div [ngClass]="{'pr-3': ((!control.touched || control.valid) || control.invalid), 'pr-10': (control.touched && control.invalid)}"
         *ngIf="suffixText" class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
      <span class="text-gray-500 sm:text-sm" [ngClass]="{'': control.touched && control.invalid}">{{ suffixText }}</span>
    </div>

    <!-- Error icon with conditional rendering and adjusted position -->
    <div *ngIf="control.touched && control.invalid" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" [ngClass]="{'pr-8': inputType === 'date' || (control.touched && control.invalid)}">
      <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </div>
  </div>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('required')" class="mt-2 text-sm text-red-600">{{!!label ? label : placeholder}} is required</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('minlength')" class="mt-2 text-sm text-red-600">{{getMinLengthMessage()}}</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('minLength')" class="mt-2 text-sm text-red-600">{{label}} doesn't have enough characters</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('maxlength')" class="mt-2 text-sm text-red-600">{{label}} has too many characters</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('maxLength')" class="mt-2 text-sm text-red-600">{{label}} has too many characters</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('min')" class="mt-2 text-sm text-red-600">{{label}} can't be negative</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('max')" class="mt-2 text-sm text-red-600">{{getMaxMessage()}}</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('email')" class="mt-2 text-sm text-red-600">Invalid email</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('minLength')" class="mt-2 text-sm text-red-600">{{getMinMessage()}}</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('invalidColor')" class="mt-2 text-sm text-red-600">The provided value is not a valid color</p>
</div>
