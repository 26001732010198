import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'tandem-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
