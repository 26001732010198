import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {ThemingService} from "../../../services/theming.service";

@Component({
  selector: 'tandem-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, AfterViewInit {

  @Input() label: string = '';
  @Input() customLabel?: string;
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() inputType: string = 'text';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() isOptional = false;
  @Input() controlName: string = '';

  @Input() tooltipText?: string;

  @Input() onFocus: () => void = () => {};
  @Input() autoFocus: boolean = false;



  @Input() prefixText?: string;
  // TODO is this gonna be needed?
  @Input() suffixText?: string;


  @ViewChild('input') inputElement!: ElementRef;

  control: FormControl = new FormControl();
  controlBlurred = false;

  constructor(private themeService: ThemingService) {
  }

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 0);
    }
  }

  getMinMessage() {
    if (this.control.getError('min')['min'] === 0) {
      return `${this.label} can't be negative`;
    } else {
      return `${this.label} must be at least ${this.control.getError('min')['min']} characters`;
    }
  }

  getMaxMessage() {
      return `${this.label} cannot be greater than ${this.control.getError('max')['max']}`;
    }

  getMinLengthMessage() {
    return `${this.label || this.placeholder} must be at least ${this.control.getError('minlength').requiredLength} characters`
  }
}
