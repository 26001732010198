import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeComponent } from './components/stripe/stripe.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import {HttpClientModule} from "@angular/common/http";
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import {StripeRoutingModule} from "./stripe-routing.module";
import {TandemCoreModule} from "../tandem-core/tandem-core.module";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    StripeComponent,
    CheckoutComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent
  ],
  exports: [
    StripeComponent,
    CheckoutComponent
  ],
    imports: [
        CommonModule,
        HttpClientModule,
        StripeRoutingModule,
        TandemCoreModule,
        ReactiveFormsModule
    ]
})
export class StripeModule { }



// 3 main fronts: bride visualizations, renewals UI, security testing/ openapi
