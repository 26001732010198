<div class="flex flex-col items-center justify-center">
  <tandem-loading-container [isLoaded]="!isLoading">
    <div class="flex flex-col justify-center items-center gap-4">
      <h5 *ngIf="errorMessage" class="text-center text-lg">{{errorMessage}}</h5>
      <tandem-button [color]="'primary'" theme="outline" [onClick]="onClose.bind(this)">Okay</tandem-button>
    </div>
  </tandem-loading-container>
  <h5 class="text-lg">Creating your account. Please don't refresh during this process. Thanks!</h5>
</div>



