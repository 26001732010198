import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {DialogService} from "../../../tandem-core/services/dialog.service";

@Component({
  selector: 'tandem-creating-account',
  templateUrl: './creating-account.component.html',
  styleUrls: ['./creating-account.component.scss']
})
export class CreatingAccountComponent implements OnInit {

  userDocSubscription?: Subscription;
  attemptedPath: string | null = null;
  successfullyFound = false;
  dialogPrompted = false;

  constructor(private auth: AuthService,
              private dialog: DialogService,
              private route: ActivatedRoute,
              private router: Router) {
    this.attemptedPath = this.route.snapshot.queryParamMap.get('attemptedPath');
  }

  ngOnInit(): void {
    this.auth.getCurrentUser().then(afAuthUser => {
      if (afAuthUser) {
        this.userDocSubscription = this.auth.$user.subscribe(user => {
          if (user) {
            this.successfullyFound = true;
            if (this.attemptedPath) {
              const urlTree = this.router.parseUrl(this.attemptedPath);
              this.router.navigate(urlTree.root.children['primary'].segments.map(it => it.path), { queryParams: urlTree.queryParams });
            } else {
              this.router.navigate(['/dashboard']);
            }
            this.userDocSubscription?.unsubscribe();
          } else {
            setTimeout(() => {
              if (!user && !this.successfullyFound) {
                this.auth.deleteCurrentUser().then(deleted => {
                  if (!this.dialogPrompted) {
                    this.dialog.openMessageDialog('Unexpected Error', `We were unable to create or retrieve your account. If you're a
                      returning user, please contact support immediately. Otherwise, try creating your account again, or contact support
                      if you're running into repeated issues.`, true).afterClosed().subscribe(res => {
                      this.router.navigate(['/auth/register'])
                    })
                    this.dialogPrompted = true;
                  }
                }).catch(err => {
                  console.log(err.message)
                })
              }
            }, 15000)
          }
        })
      }
    })


  }

}
