import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {FormOption} from "../../forms/form-option";

@Component({
  selector: 'tandem-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  @Input() pages: number[] = [1, 2, 3];

  paginationForm: FormGroup = new FormGroup<any>({});
  options: FormOption[] = []
  currentPageNumber = 1;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.paginationForm = this.fb.group({
      selectedSize: [this.pages[0]]
    });
    this.options = this.pages.map(page => {
      return {label: page.toString(), value: page}
    });
  }
}
