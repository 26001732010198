<div class="flex items-center mt-25 justify-between w-full">
  <div class="flex gap-10 items-center text-14">
    <tandem-form-select [controlName]="'selectedSize'" [form]="paginationForm" [options]="options" label="Results per page"></tandem-form-select>
  </div>
  <div class="flex flex-row items-end gap-10 h-full">
    <button
      class="square-30 hover:opacity-50 transition-quad-inout duration-250 rounded-xs text-12-caps border border-dark">
      <
    </button>
    <button
      class="square-30 bg-dark transition-quad-inout duration-250 rounded-xs text-background text-12-caps hover:cursor-auto">
      {{currentPageNumber}}
    </button>
    <button
      class="square-30 hover:opacity-50 transition-quad-inout duration-250 rounded-xs text-12-caps border border-dark">
      >
    </button>
  </div>
</div>
