<div class="fixed z-50 top-0 left-0 w-full h-60 bg-primary flex flex-row justify-between items-center px-10 md:px-25">
  <tandem-icon class="duration-350 transition-quad-inout hover:opacity-70 cursor-pointer" icon="menu" color="white" [height]="30" [width]="30" (click)="openSidenav()"></tandem-icon>
  <img [src]="environment.whiteLogoPath" class="h-full w-auto cursor-pointer">
  <!--    <img src="assets/svg/logo/full_white.svg" class="h-full w-auto cursor-pointer" [routerLink]="'dashboard'">-->

  <tandem-icon class="duration-350 transition-quad-inout hover:opacity-70 cursor-pointer" icon="userCircle" color="white" [height]="30" [width]="30" (click)="toggleUserDropdown()"></tandem-icon>
  <div class="absolute top-full mt-5 w-fit rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right right-10 z-50 p-10 tandem-dropdown" [ngClass]="{'hidden': !displayUserDropdown}">
    <div class="py-1 flex flex-col gap-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false" [routerLink]="'auth/profile'" >
        <tandem-icon class="duration-350 transition-quad-inout" icon="settings" color="black" [height]="20" [width]="20"></tandem-icon>
        <a class="block px-4 py-5 text-sm" role="menuitem">My Account</a>
      </div>
      <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false; onLogout()">
        <tandem-icon class="duration-350 transition-quad-inout" icon="logout" color="black" [height]="20" [width]="20"></tandem-icon>
        <a class="block px-4 py-5 text-sm" role="menuitem">Logout</a>
      </div>
    </div>
  </div>
</div>
