import {
  ApplicationRef, ChangeDetectorRef, ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type, ViewContainerRef
} from '@angular/core';

import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, PortalInjector} from "@angular/cdk/portal";
import {ModalRef} from "../models/modal-ref";
import {ConfirmDialogComponent} from "../components/confirm-dialog/confirm-dialog.component";
import {LoadingDialogComponent} from "../components/loading-dialog/loading-dialog.component";
import {MessageDialogComponent} from "../components/message-dialog/message-dialog.component";
import {ModalWrapperComponent} from "../components/modal-wrapper/modal-wrapper.component";

export interface AlertState {
  position: 'top' | 'bottom';
  title: string;
  content: string;
  dismissTime: number
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  $alertState: BehaviorSubject<AlertState | null> = new BehaviorSubject<AlertState | null>(null)

  emitDialogEvent: BehaviorSubject<any> = new BehaviorSubject<any>({displayModal: false});
  $dialogListener: Observable<any> = this.emitDialogEvent.asObservable();

  private renderer: Renderer2;
  private messageDialogRef?: ComponentRef<MessageDialogComponent>;
  private modalDialogRef?: ComponentRef<ModalWrapperComponent>;

  private sidePanelContent = new BehaviorSubject<ComponentRef<any> | null>(null);
  sidePanelContent$ = this.sidePanelContent.asObservable();

  private viewContainerRef?: ViewContainerRef;
  private currentComponentRef: ComponentRef<any> | null = null;

  private componentEvents = new Subject<any>();
  public componentEvents$ = this.componentEvents.asObservable();

  constructor(private overlay: Overlay,
              rendererFactory: RendererFactory2,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  literallyCloseEverything() {
    this.emitDialogEvent.next({displayModal: false});
    this.messageDialogRef = undefined;
    this.modalDialogRef = undefined;
    this.closeModal2();
  }

  openModal<T>(component: Type<T>, data?: any, config?: OverlayConfig): ModalRef {
    // this.renderer.addClass(document.body, 'modal-open');
    // this.renderer.setStyle(document.body, 'pointer-events', 'none');
    // let overlayConfig = config || new OverlayConfig({
    //
    // });
    // overlayConfig = {...{hasBackdrop: true,
    //     backdropClass: 'cdk-overlay-shaded-backdrop',
    //     panelClass: 'my-modal-class',
    //
    //     positionStrategy: this.overlay.position()  // add this line
    //       .global()
    //       .centerHorizontally()
    //       .centerVertically()}}
    //
    // const overlayRef = this.overlay.create(overlayConfig);
    // overlayRef.backdropClick().subscribe(ev => {
    //   ev.stopImmediatePropagation();
    // })
    const modalRef = new ModalRef(data);

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if(event.key === 'Escape' || event.keyCode === 27) {
        modalRef.close();
      }
    })

    // const injector = this.createModalInjector(modalRef);
    // const portal = new ComponentPortal(component, null, injector);
    //
    // overlayRef.attach(portal);
    //
    // this.focusFirstInputOrButton(overlayRef);

    // const overlayPane = overlayRef.overlayElement;
    // this.renderer.addClass(overlayPane, 'slide-up');

    // modalRef.afterClosed().subscribe(value => {
    //   this.renderer.removeClass(document.body, 'modal-open');
    //   this.renderer.removeStyle(document.body, 'pointer-events');
    // });

    this.openModalWithComponent(component, data, modalRef);
    return modalRef;
  }

  public openModal2(config: TandemDialogConfig): ComponentRef<MessageDialogComponent> {
    this.closeModal2();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MessageDialogComponent);
    const componentRef = componentFactory.create(this.injector);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    componentRef.instance.config = config;

    this.messageDialogRef = componentRef;
    return componentRef;
  }

  public closeModal2(): void {
    if (this.messageDialogRef) {
      this.appRef.detachView(this.messageDialogRef.hostView);
      this.messageDialogRef.destroy();
    }
  }

  public openModalWithComponent<T>(component: Type<T>, inputData: any, legacyRef: ModalRef): ComponentRef<ModalWrapperComponent> {
    this.closeModal2();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalWrapperComponent);
    const modalWrapperRef = componentFactory.create(this.injector);

    this.appRef.attachView(modalWrapperRef.hostView);

    const domElem = (modalWrapperRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    this.modalDialogRef = modalWrapperRef;
    // Wait for the ModalWrapperComponent to be ready
    const readySub = this.modalDialogRef.instance.onViewInitialized.subscribe(() => {
      readySub.unsubscribe(); // Prevent memory leaks

      inputData.modalRef = legacyRef;
      // this.cdr.detectChanges();
      const dynamicComponentRef = this.modalDialogRef?.instance.loadComponent(component, inputData);
      // this.cdr.detectChanges();
    });

    legacyRef.afterClosed().subscribe(() => {
      this.appRef.detachView(modalWrapperRef.hostView);
      modalWrapperRef.destroy();
      this.closeModal2()
    });

    // Subscribe to the onClose event
    this.modalDialogRef?.instance.onClose.subscribe(result => {
      legacyRef.close(result);
    });

    return modalWrapperRef;
  }

  public showAlert(alertState: AlertState) {
    this.$alertState.next(alertState);
  }

  public openConfirmDialog(title: string,
                           message: string,
                           confirmText: string = 'Confirm',
                           cancelText: string = 'Cancel',
                           hideConfirm: boolean = false,
                           hideCancel: boolean = false,
                           confirmIsPrimary: boolean = false,
                           promptContactEmail = false): ModalRef {
    this.emitDialogEvent.next({displayModal: true});
    return this.openModal(ConfirmDialogComponent, {title: title, message: message, confirmText: confirmText, cancelText: cancelText, hideConfirm: hideConfirm, hideCancel: hideCancel, confirmIsPrimary: confirmIsPrimary, promptContactEmail: promptContactEmail})
  }

  public openConfirmDialog2(title: string,
                           message: string,
                           confirmText: string = 'Confirm',
                           cancelText: string = 'Cancel',
                           hideConfirm: boolean = false,
                           hideCancel: boolean = false,
                           confirmIsPrimary: boolean = false,
                           promptContactEmail = false): ModalRef {
    this.emitDialogEvent.next({displayModal: true});
    return this.openModal(ConfirmDialogComponent, {title: title, message: message, confirmText: confirmText, cancelText: cancelText, hideConfirm: hideConfirm, hideCancel: hideCancel, confirmIsPrimary: confirmIsPrimary, promptContactEmail: promptContactEmail})
  }

  public openMessageDialog(title: string, message: string, promptContactEmail = false) {
    return this.openConfirmDialog(title, message, '', 'Okay', true, false, true, promptContactEmail);
  }

  public openLoadingDialog(title: string,
                           message: string): ModalRef {
    return this.openModal(LoadingDialogComponent, {title: title, message: message})
  }

  public showLoadingDialog(message?: string) {
    let dialog: TandemDialogConfig = {
      title: 'Loading',
      content: message ? message : `Please wait while we process your request`,
      type: 'loading',
      actions: [
      ]
    }
    this.openModal2(dialog);
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  openSidePanel<T>(component: new (...args: any[]) => T, data?: { [key: string]: any }) {
    if (!this.viewContainerRef) {
      throw new Error('ViewContainerRef not set. Call setViewContainerRef first.');
    }

    this.viewContainerRef.clear();
    this.currentComponentRef = this.viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(component)
    );

    if (data) {
      this.updateComponentData(data);
    }

    if (this.currentComponentRef && this.currentComponentRef.instance.chatEvent) {
      this.currentComponentRef.instance.chatEvent.subscribe((event: any) => {
        this.componentEvents.next(event);
      });
    }

    this.sidePanelContent.next(this.currentComponentRef);
    return this.currentComponentRef.instance;
  }

  updateComponentData(data: { [key: string]: any }) {
    if (this.currentComponentRef) {
      Object.keys(data).forEach(key => {
        this.currentComponentRef!.instance[key] = data[key];
      });
      if (this.currentComponentRef.instance['updateHandler']) {
        this.currentComponentRef.instance['updateHandler']();
      }
      this.currentComponentRef.changeDetectorRef.detectChanges();
    }
  }

  closeSidePanel() {
    this.viewContainerRef?.clear();
    this.currentComponentRef = null;
    this.sidePanelContent.next(null);
  }

  private createModalInjector(modalRef: ModalRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ModalRef, modalRef);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private focusFirstInputOrButton(overlayRef: OverlayRef): void {
    setTimeout(() => {
      const overlayElement = overlayRef.overlayElement;
      const firstInputOrButton = overlayElement.querySelector('input, button');
      if (firstInputOrButton) {
        (firstInputOrButton as HTMLElement).focus();
      }
    });
  }

  public getPanelComponent(): ComponentRef<any> | null {
    return this.currentComponentRef;
  }
}

export interface TandemDialogConfig {
  type: 'confirm' | 'loading' | 'success' | 'failure' | 'info';
  title?: string;
  content?: string;
  actions?: TandemDialogAction[];
  hideImage?: boolean;
  // Additional properties as needed
}

export interface TandemDialogAction {
  text: string;
  role: 'confirm' | 'cancel' | 'custom' | 'contact' | 'close' | 'primary';
  callback?: () => void;
}
