import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AuthService } from '../../../modules/auth/services/auth.service';
import {IconType} from "../../../modules/tandem-core/components/icons/icon-type";
import {DialogService} from "../../../modules/tandem-core/services/dialog.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'tandem-sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit {
  isExpanded = false;
  @Input() emailVerified = false;
  @Input() displayProfile = false;

  @Input() displaySidenav: boolean = false;

  sections: {title: string, items: {icon: IconType, label: string, routerLink: string, authProp: string}[] }[] = [];

  @Output() sidenavClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private auth: AuthService,
              private dialog: DialogService,
              private router: Router) {}

  toggleExpanded() {
    this.sidenavClosed.emit();
    // this.isExpanded = !this.isExpanded;
    // console.log(this.isExpanded)
  }

  onLogout() {
    this.dialog.openConfirmDialog('Logout', 'Are you sure you want to logout?', 'Logout', 'Cancel').afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.auth.onLogout();
      }
    })
  }

  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      // this.expandedToggle$.subscribe(() => this.toggleExpanded());
      this.sections = [];
      console.log(user?.customTTMetadata)
      this.sections = [
        {
          title: 'Tracking Tools',
          items: [
            {
              icon: 'financialPosition',
              label: user?.customTTMetadata?.fpNamePlural ? `${user.customTTMetadata.fpNamePlural}` : 'Financial Positions',
              routerLink: 'financial-positions',
              authProp: 'trackingTools',
            },
            {
              icon: 'cashFlow',
              label: user?.customTTMetadata?.cashFlowNamePlural ? `${user.customTTMetadata.cashFlowNamePlural}` : 'Cash Flows',
              routerLink: 'cash-flows',
              authProp: 'trackingTools',
            },
            {
              icon: 'spendingPlan',
              label: user?.customTTMetadata?.spendingPlanNamePlural ? `${user.customTTMetadata.spendingPlanNamePlural}` : 'Spending Plans',
              routerLink: 'spending-plans',
              authProp: 'trackingTools',
            },
          ]
        },
        {
          title: 'Calculators',
          items: [
            {
              icon: 'graphDown',
              label: 'Debt Payoff Calculator',
              routerLink: 'calculators/debt-payoff',
              authProp: 'calculators',
            },
            {
              icon: 'graphUp',
              label: 'Investment Calculator',
              routerLink: 'calculators/investment',
              authProp: 'calculators',
            },
            {
              icon: 'lineGraph',
              label: 'Debt Vs. Invest',
              routerLink: 'calculators/debt-vs-invest',
              authProp: 'calculators',
            },
          ]
        },
        {
          title: 'Coaching',
          items: [
            {
              icon: 'userMultiple',
              label: 'User Management',
              routerLink: 'coaching/user-management',
              authProp: 'isCoach',
            },
          ]
        },
      ];
    })
  }

  get sidenavActiveClass() {
    return this.displaySidenav ? 'sidenav-active' : '';
  }

  protected readonly environment = environment;
}
