<div *ngIf="control" class="mb-5">
  <label [for]="inputId" class="block text-sm font-medium leading-6 text-gray-900">{{customLabel ? customLabel : label}}</label>
  <div class="relative mt-2 rounded-md shadow-sm">
    <textarea
      #input
      [formControl]="control"
      [name]="inputId"
      [rows]="rows"
      [cols]="cols"
      [id]="inputId"
       [ngClass]="{
         'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-red-500': (control.dirty || control.touched) && control.invalid,
         'pr-12': control.invalid && (control.dirty || control.touched),
       }"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-primary sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
      [placeholder]="placeholder"
      [attr.aria-describedby]="inputId"
      [attr.aria-invalid]="(control.touched && control.invalid) ? true : null"></textarea>
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </div>
  </div>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('required')" class="mt-2 text-sm text-red-600">{{label}} is required</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('minlength')" class="mt-2 text-sm text-red-600">{{label}} must be at least {{control.getError('minlength').requiredLength}} characters</p>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('maxlength')" class="mt-2 text-sm text-red-600">{{label}} can't be more than {{control.getError('maxlength').requiredLength}} characters</p>
</div>
