import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {ThemingService} from "../../../services/theming.service";

@Component({
  selector: 'tandem-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, AfterViewInit {

  @Input() label: string = '';
  @Input() customLabel?: string;
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() inputType: string = 'text';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() isOptional = false;
  @Input() hideErrorMessage = false;
  @Input() controlName: string = '';
  @Input() tooltipText?: string;
  @Input() onFocus: () => void = () => {};
  @Input() autoFocus: boolean = false;

  @Input() prefixText?: string;
  @Input() suffixText?: string;

  // Add these new input properties
  @Input() maskType?: 'currency' | 'percent' | 'phone' | 'number';
  @Input() percentDecimalPlaces?: number;
  @Input() numericDecimalPlaces: number = 0;

  @ViewChild('input') inputElement!: ElementRef;

  control: FormControl = new FormControl();
  controlBlurred = false;

  // Variables for ngx-mask
  mask: string = '';
  dropSpecialCharacters: boolean | string[] = true;
  thousandSeparator: string = '';
  decimalMarker: '.' | ',' | ['.', ','] = '.';
  allowNegativeNumbers: boolean = false;

  constructor(private themeService: ThemingService) {}

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
    this.setupMask();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 0);
    }
  }

  // New method to set up the mask based on maskType
  setupMask() {
    if (this.maskType === 'currency') {
      this.inputType = 'text'; // Ensure input type is text
      const decimalPlaces = 2;
      this.mask = `separator.${decimalPlaces}`; // Allows two decimal places
      this.thousandSeparator = ',';
      this.dropSpecialCharacters = false; // Keeps the formatting in the input
      this.allowNegativeNumbers = false;
    } else if (this.maskType === 'percent') {
      this.inputType = 'text'; // Ensure input type is text
      const decimalPlaces = this.percentDecimalPlaces ?? 4;
      this.mask = `separator.${decimalPlaces}`; // Allows specified decimal places
      this.thousandSeparator = ',';
      this.dropSpecialCharacters = false;
      this.allowNegativeNumbers = false;
    } else if (this.maskType === 'phone') {
      this.inputType = 'tel'; // Optional: Use 'tel' for phone numbers
      this.mask = '(000) 000-0000'; // Phone number format
      this.dropSpecialCharacters = true; // Stores only digits
    }  else if (this.maskType === 'number') {
      this.inputType = 'text';
      const decimalPlaces = this.numericDecimalPlaces;
      this.mask = `separator.${decimalPlaces}`;
      this.thousandSeparator = ',';
      this.dropSpecialCharacters = false;
      this.allowNegativeNumbers = true; // Set to false if negatives aren't allowed
    } else {
      // For other types, you can keep the inputType as is or set default
      this.inputType = this.inputType || 'text';
      this.mask = '';
      this.dropSpecialCharacters = true;
    }
  }

  getMinMessage() {
    if (this.control.getError('min')['min'] === 0) {
      return `${this.label} can't be negative`;
    } else {
      return `${this.label} must be at least ${this.control.getError('min')['min']} characters`;
    }
  }

  getMaxMessage() {
      return `${this.label} cannot be greater than ${this.control.getError('max')['max']}`;
    }

  getMinLengthMessage() {
    return `${this.label || this.placeholder} must be at least ${this.control.getError('minlength').requiredLength} characters`
  }
}
