<div class="w-full flex flex-col gap-15">
  <ng-container  *ngFor="let institution of institutions">
    <h4>{{institution.institutionName}}</h4>
    <div class="flex flex-row flex-wrap gap-15">
      <tandem-button *ngFor="let acc of institution.accounts" theme="solid" [onClick]="queryItem.bind(this, institution, acc)">{{acc.name}}</tandem-button>
    </div>
  </ng-container>
  <tandem-button color="primary" theme="outline" [onClick]="openPlaid.bind(this)">Add new connection</tandem-button>
</div>

