import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import firebase from "firebase/compat";
import User = firebase.User;
import {UserService} from "../../user.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {DialogService, TandemDialogConfig} from "../../../tandem-core/services/dialog.service";
import {TandemUser} from "../../models/tandem-user";

@Component({
  selector: 'tandem-email-not-verified',
  templateUrl: './email-not-verified.component.html',
  styleUrls: ['./email-not-verified.component.scss']
})
export class EmailNotVerifiedComponent implements OnInit, OnDestroy {

  resent = false;
  public currentUser: User | null = null;
  public authState: firebase.User | null = null;
  oobCode: string | null = null;
  private SERVER_PATH = environment.functionsPath;
  intervalId: any;
  public user: User | null = null;

  constructor(private auth: AuthService,
              private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              private route: ActivatedRoute,
              private dialogService: DialogService,
              private router: Router,
              private userService: UserService,
              private http: HttpClient) {
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe(user => {
      this.user = user;
    })
    this.intervalId = setInterval(() => {
      this.auth.reloadUser();
      // TODO if we complain about the success message automatically closing, we need to rework how the auth service handles dialog closes
      // This should set a property on the auth service that prevents closeModal2() from being called until we get our user's successful verification here
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  // ngOnInit(): void {
  //
  //   this.afAuth.authState.subscribe(authState => {
  //     if (this.oobCode) {
  //       this.handleEmailAction(authState?.email, this.oobCode)
  //     }
  //
  //     this
  //     if (!authState?.emailVerified) {
  //       this.auth.reloadUser();
  //     }
  //   })
  //
  //   // this.afAuth.authState.subscribe(authState => this.authState = authState);
  //   // this.auth.getCurrentUser().then(currentUser => {
  //   //   console.log(currentUser)
  //   //   this.currentUser = currentUser;
  //   //   if (currentUser) {
  //   //     this.userService.get(currentUser.uid).subscribe(firebaseUser => {
  //   //       if (firebaseUser.verified) {
  //   //         this.router.navigate(['dashboard']);
  //   //       } else {
  //   //         if (currentUser.emailVerified) {
  //   //           this.router.navigate(['dashboard']);
  //   //         }
  //   //       }
  //   //     })
  //   //   } else {
  //   //     this.router.navigate(['auth/login'])
  //   //   }
  //   // });
  //
  // }

  resendVerification() {
    this.dialogService.showLoadingDialog('Please wait, we\'re sending you another email.');
    this.resent = false;
    if (this.user) {
      this.auth.resendEmailVerification(this.user.email!).subscribe(res => {
        this.dialogService.openModal2({
          title: 'Resent Email',
          content: `Successfully sent a verification link to ${this.user?.email}. Please check your inbox again.`,
          type: 'success',
          actions: [{text: 'Close', role: 'close', callback: () => this.dialogService.closeModal2()}]
        });
      }, error => {
        this.dialogService.openConfirmDialog('Give us a few Minutes', `Due to security risks, our database limits the number of email verification messages sent in a given time window. Please wait a few minutes and then try requesting another email verification.
Alternatively, you can send a message to support to verify your account. Keep in mind it may be a few hours before customer support is able to address your request.
We appreciate your patience, you'll be crushing your financial goals in no time!`, '', 'Close', true, false, false, true);
      });
    }
  }

  refresh() {
    // this.auth.getCurrentUser().then(user => {
    //   user?.reload().then(reloaded => {
    //   })
    // })
  }

  logout() {
    this.auth.onLogout().then(res => this.router.navigate(['auth/login']))
  }

  handleEmailAction(userId: string | null | undefined, oobCode: string) {
    this.afAuth.verifyPasswordResetCode(oobCode).then(email => {
      // The email is verified. Update Firestore.
      // You might need some way to get the user's ID based on their email.
      if (userId) {
        this.afs.doc(`users/${userId}`).update({ verified: true }).then(res => {
          this.afAuth.currentUser.then(currUser => {
            currUser?.getIdToken(true).then(idToken => {
              this.afs.collection<TandemUser>('users').doc(currUser?.uid).update({firstLogin: false}).then(res => {
                this.router.navigate(['dashboard']);
              })
            })
          })
        });
      } else {
        this.sendVerificationUpdateRequest(email, oobCode).then(res => {
          if (res.success) {
            this.dialogService.openConfirmDialog('Successfully Verified Email',
              `You've successfully verified ${email}. You may now go back to the tab you originally signed up on, or continue to sign in with your new account here.`,
              'Sign In',
              'Close').afterClosed().subscribe(confirmed => {
                if (confirmed) {
                  this.router.navigate(['auth/login']);
                }
            })
          }
        })
      }
    }).catch(error => {
      // Handle error, maybe the code has expired or is invalid.
    });
  }

  public async sendVerificationUpdateRequest(email: string, oobCode: string) {

    const params = new HttpParams().set('email', email).set('oobCode', oobCode);
    return this.http.get<any>(`${this.SERVER_PATH}/verifyEmailRequest`,{ params }).toPromise();
  }

  protected readonly environment = environment;
}
