import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'tandem-connect-refresh',
  templateUrl: './connect-refresh.component.html',
  styleUrls: ['./connect-refresh.component.scss']
})
export class ConnectRefreshComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.router.navigate(['/auth/profile'])
  }
}
