import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import {AuthService} from "../services/auth.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Directive({
  selector: '[tandemAuth]'
})
export class AuthDirective implements OnInit {

  @Input('tandemAuth') claims: string | string[] = [];
  @Input('tandemAuthCheckType') checkType: 'AND' | 'OR' = 'OR';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {
    if (this.claims === '*' || this.claims.includes('*')) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.authService.$user.subscribe(user => {
        this.viewContainer.clear();
        if (!user) {
          return;
        }

        this.afAuth.currentUser.then(user => {
          user?.getIdTokenResult().then(token => {
            let userClaims = token.claims;

            if (Array.isArray(this.claims)) {
              let result: boolean;

              if (this.checkType === 'AND') {
                result = this.claims.every(claim => userClaims['includes'](claim));
              } else {
                // Default is 'OR'
                result = this.claims.some(claim => userClaims['includes'](claim));
              }

              if (!result) {
                this.viewContainer.clear();
                return;
              }

              this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
              if (!userClaims[this.claims]) {
                this.viewContainer.clear();
                return;
              }
              this.viewContainer.createEmbeddedView(this.templateRef);
            }
          });
        });
      });
    }
  }
}
