import {Component, Inject, Input} from '@angular/core';

@Component({
  selector: 'tandem-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent {

  @Input() chartType: string = 'line';
  @Input() results: any[] = [];
  @Input() view: [number, number] = [700, 400]; // adjust chart size here

  // Customize the color scheme for the charts
  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  // constructor(@Inject(ModalRef) public modalRef: ModalRef) {
  constructor() {
  }

  // closeModal(): void {
  //   this.modalRef.close('This is a result');
  // }
}
