export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBcMsulSjECZulDwzccIjnSGpCxT-pAsks",
    authDomain: "tandem-finance-tst.firebaseapp.com",
    projectId: "tandem-finance-tst",
    storageBucket: "tandem-finance-tst.appspot.com",
    messagingSenderId: "353426925647",
    appId: "1:353426925647:web:0e00154410851dce01623a",
    measurementId: "G-T4RHKJ6XFY"
  },
  environmentName: 'Test',
  stripePublicKey: 'pk_test_51ODUUiEIxj4fplMbKFOzhevgkbwc2zbC57n3FHbnx1L9jbWQ3t4cF56GtXiF1x9Cv44FjwOrGaXGp4IUD9nSeN2w00FasWUWsC',
  functionsPath: 'https://us-central1-tandem-finance-tst.cloudfunctions.net',
  logoPath: 'assets/svg/logo/full_blue.svg',
  whiteLogoPath: 'assets/svg/logo/full_white.svg',
  whiteIconPath: 'assets/svg/logo/icon_white.svg',
  primaryIconPath: 'assets/svg/logo/icon_blue.svg',
  defaultTheme: 'rgb(70,96,234)',
  defaultHeaderFont: 'DM Sans',
  defaultBodyFont: 'DM Sans',
  enableGridDisplay: false,
  defaultVideoConfig: {
    userWelcomeRev: 'https://www.youtube.com/embed/MhTRPn1qEU4?si=mFjgdmOSS7M7G6Zy',
    userWelcomeLead: 'https://www.youtube.com/embed/HwMBD5ftn1w?si=31pYbb_SaViUsMOZ',
    userWelcomeIndividual: 'https://www.youtube.com/embed/XxFuLNn55zs',
    coachWelcomeRev: 'https://www.youtube.com/embed/LiiQSVc-djg?si=m97SyuHrn4ZJtP5M',
    coachWelcomeLead: 'https://www.youtube.com/embed/NZIJh67PrCo?si=p2WD-LHuRSF45NDJ',
    financialPosition: 'https://www.youtube.com/embed/XxFuLNn55zs',
    cashFlow: 'https://www.youtube.com/embed/XxFuLNn55zs',
    spendingPlan: 'https://www.youtube.com/embed/XxFuLNn55zs',
    debtPayoff: 'https://www.youtube.com/embed/XxFuLNn55zs',
    investment: 'https://www.youtube.com/embed/XxFuLNn55zs',
    debtVsInvest: 'https://www.youtube.com/embed/XxFuLNn55zs',
  },
  defaultFPid: 'GjJqgcBzo3tLLG8IVpK6',
  defaultCFid: 'S24TCb2YD3d4SOxlje36',
  defaultSPid: 'VVwgznZUEGUvPyAfi8qF',
  defaultDomain: 'https://tst.tandemfinance.app',
  // defaultCoachId: 'bDjbMEJCKvOkdiD2giuMwMtceDs1'
  defaultCoachId: '8UiiFbfB5gSXy8I57f0SHshrr5S2'
};
