import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PaymentSuccessComponent} from "./components/payment-success/payment-success.component";
import {PaymentErrorComponent} from "./components/payment-error/payment-error.component";
import {ConnectRefreshComponent} from "./components/connect-refresh/connect-refresh.component";
import {ConnectReturnComponent} from "./components/connect-return/connect-return.component";


const routes: Routes = [
  {
    path: 'payment-success',
    component: PaymentSuccessComponent
  },
  {
    path: 'payment-error',
    component: PaymentErrorComponent
  },
  {
    path: 'connect-refresh',
    component: ConnectRefreshComponent
  },
  {
    path: 'connect-return',
    component: ConnectReturnComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StripeRoutingModule{ }
