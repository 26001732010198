import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaidComponent } from './components/plaid/plaid.component';
import {TandemCoreModule} from "../tandem-core/tandem-core.module";



@NgModule({
    declarations: [
        PlaidComponent
    ],
    exports: [
        PlaidComponent
    ],
    imports: [
        CommonModule,
        TandemCoreModule
    ]
})
export class PlaidModule { }
