import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AbstractDatabaseObject} from "../models/abstract-database-object";
import {AbstractDatabaseService} from "./abstract-database.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export type VideoType = 'userWelcomeRev'
  | 'userWelcomeLead'
  | 'userWelcomeIndividual'
  | 'coachWelcomeRev'
  | 'coachWelcomeLead'
  | 'financialPosition'
  | 'cashFlow'
  | 'spendingPlan'
  | 'debtPayoff'
  | 'investment'
  | 'debtVsInvest';
export interface VideoUrl extends AbstractDatabaseObject {
  coachId: string;
  type: VideoType;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService extends AbstractDatabaseService<VideoUrl> {

  constructor(protected override afs: AngularFirestore) {
    super('videoUrls', afs);
  }

  public findByCoachAndType(coachId: string, videoType: VideoType): Observable<VideoUrl | null> {
    return this.afs.collection<VideoUrl>(this.path, ref => ref.where('coachId', '==', coachId).where('type', '==', videoType))
      .snapshotChanges()
      .pipe(
        map(changes => {
          if (changes.length > 0) {
            const data = changes[0].payload.doc.data() as VideoUrl;
            data.id = changes[0].payload.doc.id;
            return data;
          } else {
            return null; // or throw an error or handle this case as per your requirement
          }
        })
      );
  }

  public listByCoachId(coachId: string): Observable<VideoUrl[]> {
    return this.afs.collection<VideoUrl>(this.path, ref => ref.where('coachId', '==', coachId))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as VideoUrl;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
}
