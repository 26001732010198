import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PlaidService} from "../../services/plaid.service";
import {LinkData} from "../../models/link-data";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {LinkedInstitution, PlaidAccount} from "../../models/linked-institution";

@Component({
  selector: 'tandem-plaid',
  templateUrl: './plaid.component.html',
  styleUrls: ['./plaid.component.scss']
})
export class PlaidComponent implements OnInit {

  private linkToken: string | undefined;
  private plaidHandler: any;
  institutions: LinkedInstitution[] = [];

  constructor(private dialog: DialogService,
              private http: HttpClient,
              private plaidService: PlaidService) {
    this.getLinkToken();
  }

  ngOnInit(): void {
        this.plaidService.getLinkedInstitutions().then(institutions => {
          if (institutions) {
            this.institutions = institutions;
          }
        });
    }

  getLinkToken() {
    this.plaidService.getLinkToken().then(response => {
      console.log(response)
      this.linkToken = response?.link_token;
      console.log(this.linkToken);
      this.initializePlaid();
    })
  }

  initializePlaid() {
    console.log(this.linkToken);
    this.plaidHandler = (window as any).Plaid.create({
      token: this.linkToken,
      onSuccess: (public_token: string, metadata: LinkData) => {
        console.log('sending public token to server')
        // Send the public_token to your server here!
        // console.log('Public Token:', public_token);
        // console.log('Link Account Metadata:', metadata);
        this.plaidService.processLinkData(metadata).then(response => {
          console.log('successfully saved data in firebase!');
          this.dialog.openConfirmDialog('Account Linked', 'Successfully linked your account!', '', 'Ok', true, false)
          console.log(response?.message);
        }).catch(err => {
          console.log('we weren\'t able to save the data')
          console.error(err);
        })
      },
    });
  }

  openPlaid() {
    if (this.plaidHandler) {
      this.plaidHandler.open();
    }
  }

  queryItem(institution: LinkedInstitution, account: PlaidAccount) {
    this.plaidService.getAccountBalance(account.id).then(res => {
      console.log(res);
    })
  }
}
