import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, combineLatest, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class NotSignedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.$user.pipe(
      take(1),
      map(user => !user), // If there's no user, allow access
      tap(unauthenticated => {
        return of(true)
        if (!unauthenticated) {
          // If user is authenticated, redirect to home or dashboard
          // this.router.navigate(['/dashboard']);
        }
      })
    );
  }
}
