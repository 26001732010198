<tr class="w-full flex block items-center relative">
  <tandem-form-checkbox *ngIf="hasCheckbox" [control]="isChecked" (checkboxToggled)="onToggle($event)"></tandem-form-checkbox>
  <div
    class="border-[1px] w-full rounded-sm text-14 px-15 py-11 flex items-center justify-between border-dark/20 [&>td]:w-full [&>td:last-child]:text-right [&>td:first-child]:text-left"
  >
    <img
      *ngIf="type === 'draggable'"
      class="cursor-grab active:cursor-grabbing mr-10 hover:opacity-50 duration-250 transition-quad-inout"
      src="../../../../../../assets/icons/drabbagle.png"
      alt="dragging icon"
    />
    <ng-content />
    <div class="relative" *ngIf="hasMeatball">
      <tandem-icon icon="circle-menu" color="black" (click)="toggleDropdown($event)"></tandem-icon>

      <div #dropdownMenu *ngIf="rowDropdownVisible" class="example-menu" cdkMenu [ngClass]="{'display-none': !rowDropdownVisible}">
        <button (click)="$event.stopPropagation(); onEdit.emit(); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="edit" color="black" class="mr-10"></tandem-icon>EDIT</button>
        <button *ngIf="showMove" (click)="$event.stopPropagation(); onMove.emit(); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="move" color="black" class="mr-10"></tandem-icon>UNCATEGORIZE</button>
        <button (click)="delete($event); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="trash" color="red" class="mr-10"></tandem-icon><span class="text-red">DELETE</span></button>
      </div>
    </div>
  </div>
</tr>
