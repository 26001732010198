import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {ModalRef} from "../../models/modal-ref";
import {SendEmailDialogComponent} from "../send-email-dialog/send-email-dialog.component";

@Component({
  selector: 'tandem-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements AfterViewInit {

  title: string = '';
  message: string = '';
  confirmText: string = 'Confirm';
  cancelText: string = 'Cancel';

  hideCancel = false;
  hideConfirm = false;

  confirmIsPrimary: boolean = false;
  promptContactEmail: boolean = false;

  public modalRef?: ModalRef

  constructor(private dialogService: DialogService, private cdr: ChangeDetectorRef
              ) {
    if (this.modalRef) {
      this.title = this.modalRef.data?.title;
      this.message = this.modalRef.data?.message;
      this.confirmText = this.modalRef.data?.confirmText;
      this.cancelText = this.modalRef.data?.cancelText;
      this.hideCancel = this.modalRef.data?.hideCancel;
      this.hideConfirm = this.modalRef.data?.hideConfirm;
      this.confirmIsPrimary = this.modalRef.data?.confirmIsPrimary;
      this.promptContactEmail = this.modalRef.data?.promptContactEmail;
    }

  }

  ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

  closeModal(result: boolean) {
    this.modalRef?.close(result)
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterPressed(event: KeyboardEvent): void {
    this.closeModal(true);
  }

  openContactDialog() {
    this.modalRef?.close();
    this.dialogService.closeModal2();
    this.dialogService.openModal(SendEmailDialogComponent, {});
  }
}
