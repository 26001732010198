<div class="flex p-10 border-2 border-background cols-4 justify-center align-middle">
<tandem-form-input
  [form]="completeRegistrationForm"
  [controlName]="'firstName'"
  [inputType]="'text'"
  [inputId]="'firstName'"
  [label]="'First Name'"
  [placeholder]="'First Name'"></tandem-form-input>
<tandem-form-input
  [form]="completeRegistrationForm"
  [controlName]="'lastName'"
  [inputType]="'text'"
  [inputId]="'lastName'"
  [label]="'Last Name'"
  [placeholder]="'Last Name'"></tandem-form-input>
</div>

