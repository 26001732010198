// import {Component, Input} from '@angular/core';
//
// @Component({
//   selector: 'tandem-tooltip',
//   templateUrl: './tooltip.component.html',
//   styleUrls: ['./tooltip.component.scss']
// })
// export class TooltipComponent {
//
//   @Input() text: string = '';
//   @Input() maxWidth?: string;
//
//   tooltipExpanded = false;
//
//   constructor() {
//   }
// }
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tandem-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() text: string = '';
  showTooltip: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
