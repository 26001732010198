
<div *ngIf="control" class="mb-5">
  <div class="relative flex items-start">
    <div class="flex h-6 items-center">
      <input [disabled]="disabled" [checked]="control.value" [formControl]="control" [id]="inputId" [attr.aria-describedby]="inputId + '-description'" [name]="inputId" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary">
    </div>
    <div class="ml-3 text-sm leading-6">
      <label [for]="inputId" class="font-medium text-gray-900">{{ label }}</label>
      <p *ngIf="description" [id]="inputId + '-description'" class="text-gray-500">{{description}}</p>
    </div>
    <div class="flex flex-row gap-1 ml-2 pb-4">
      <tandem-tooltip *ngIf="tooltipText" [text]="tooltipText"></tandem-tooltip>
    </div>
  </div>
  <p *ngIf="control.invalid && (control.dirty || control.touched) && control.hasError('required')" class="mt-2 text-sm text-red-600">{{label}} is required</p>
</div>
