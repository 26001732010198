import {Component, Input} from '@angular/core';
import {ThemeType} from "../../../models/theme-type";

@Component({
  selector: 'tandem-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() theme: ThemeType = 'background';
  @Input() isDashedBorder: boolean = false;
  @Input() useFullHeight: boolean = false;
  @Input() scrollable: boolean = false;
}
