<ng-container>
  <div class="sm:flex sm:items-start">
    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
      </svg>
    </div>
    <div class="mt-3 text-center items-center sm:items-start sm:ml-4 sm:mt-0 sm:text-left flex flex-col">
      <h3 class="text-base font-semibold leading-6 text-gray-900" id="confirm-title">{{title}}</h3>
      <div class="mt-2 w-3/4 sm:w-full">
        <p class="text-sm text-gray-500">{{message}}</p>
      </div>
    </div>
  </div>
  <div class="mt-5 flex flex-col-reverse sm:mt-4 sm:flex-row justify-end">
    <button [type]="'button'" *ngIf="cancelText"
              class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm mt-3 sm:ml-3 sm:w-auto bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              (click)="closeModal(false)">{{cancelText}}</button>
    <button [type]="'submit'" *ngIf="confirmText && confirmText !== ''"
            class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm mt-3 sm:ml-3 sm:w-auto bg-red-600 hover:bg-red-500 text-white"
            (click)="closeModal(true)">{{confirmText}}</button>
    <button *ngIf="promptContactEmail" [type]="'button'"
            class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm mt-3 sm:ml-3 sm:w-auto bg-red-600 hover:bg-red-500 text-white"
            (click)="openContactDialog()">Contact Support</button>
  </div>
</ng-container>
