import { Injectable } from '@angular/core';
import {AbstractDatabaseService} from "../tandem-core/services/abstract-database.service";
import {AngularFirestore, AngularFirestoreCollection, CollectionReference} from "@angular/fire/compat/firestore";
import {TandemUser} from "./models/tandem-user";
import {catchError, from, Observable, of, switchMap} from "rxjs";
import {CashFlowTemplate} from "../cash-flows/models/cash-flow-template";
import {map} from "rxjs/operators";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from "firebase/compat";
import Query = firebase.firestore.Query;
import {UserSubscription} from "./models/user-subscription";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractDatabaseService<TandemUser> {

  constructor(protected override afs: AngularFirestore,
              private afAuth: AngularFireAuth) {
    super('users', afs);
  }

  public getUsersByCoachId(coachId: string, status?: 'Active' | 'Paused' | 'Pending'): Observable<TandemUser[]> {

    return from(this.afAuth.currentUser).pipe(
      switchMap(user => {
        if (user) {
          console.log(user);
          return this.afs.collection<TandemUser>('users', ref => {

            let query: CollectionReference | Query = ref.where('coachId', '==', coachId);
            if (status) {
              query = query.where('status', '==', status);
            }
            return query;
          })
            .snapshotChanges()
            .pipe(
              map(changes => {
                return changes.map(a => {
                  const data = a.payload.doc.data() as TandemUser;
                  data.id = a.payload.doc.id;
                  return data;
                });
              })
            );
        } else {
          return of([]);
        }
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  public getPendingCoaches(): Observable<TandemUser[]> {

    return from(this.afAuth.currentUser).pipe(
      switchMap(user => {
        if (user) {
          console.log(user);
          return this.afs.collection<TandemUser>('users', ref => {

            let query: CollectionReference | Query = ref
            // where('status', '==', 'AwaitingAdminApproval')
            .where('accountType', '==', 'coach')
            if (status) {
              query = query.where('status', '==', status);
            }
            return query;
          })
            .snapshotChanges()
            .pipe(
              map(changes => {
                return changes.map(a => {
                  const data = a.payload.doc.data() as TandemUser;
                  data.id = a.payload.doc.id;
                  return data;
                });
              })
            );
        } else {
          return of([]);
        }
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  updateUserName(userId: string, firstName: string, lastName: string, phoneNumber: string | undefined) {
    const displayName = `${firstName} ${lastName}`;
    return new Promise<void>((resolve, reject) => {
      const updateData: Partial<TandemUser> = {
        firstName,
        lastName,
        displayName,
        hasPromptedName: true
      };

      if (phoneNumber !== undefined) {
        updateData.phoneNumber = phoneNumber;
      }

      this.collection
        .doc<TandemUser>(userId)
        .update(updateData)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }

  getSubscriptionInformationForUser(userId: string): Observable<UserSubscription | undefined> {
    return this.afs.collection<UserSubscription>('userSubscriptions').doc(userId).valueChanges();
  }
}
