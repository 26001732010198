import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";

@Component({
  selector: 'tandem-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
  public registerForm: FormGroup = new FormGroup<any>({});

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  coachId: string | null = null;
  preventButtonPress = false;

  passwordErrorMessage: string | null = null;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private fb: FormBuilder) {
    this.coachId = this.route.snapshot.queryParamMap.get('coachId');
  }

  ngOnInit() {
    this.themingService.brandingConfig.subscribe(config => {
      if (config.logoURL) {
        this.logoPath = config.logoURL
      }
    })
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.pattern(this.PASSWORD_REGEX)]]
    });
    this.registerForm.get('password')?.valueChanges.subscribe(pass => {
      if(this.registerForm.get('password')?.dirty) {
        this.passwordErrorMessage = this.validatePassword(pass);
      }
    })
  }

  onGoogleLogin() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithGoogle({coachId: this.coachId || undefined, accountType: 'user'}).then(res => {
      }).catch(err => {
        this.preventButtonPress = false;
      }).finally(() => {
        this.preventButtonPress = false;
      });
    }
  }

  displayError(message: string): void {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = "";
    }, 3000)
    this.preventButtonPress = false;
  }

  registerWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      if (this.registerForm.invalid) {
        this.displayError("Please correct the form errors before submitting.");
      } else {
        this.auth.registerWithEmailPass(
          this.registerForm.get('email')?.value,
          this.registerForm.get('password')?.value,
          {coachId: this.coachId || undefined, accountType: 'user'},
        ).then(res => {
          // Handle successful registration
        }).catch(error => {
          this.displayError("Registration failed. Please try again.");
        }).finally(() => {
          this.preventButtonPress = false;
        });
      }
    }
  }

  validatePassword(password: string): string | null {
    if (!password) return "Password is required.";
    if (password.length < 8) return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one capital letter.";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character (!@#$%^&*).";
    return null;
  }

  protected readonly environment = environment;

  goToLogin() {
    this.router.navigate(['/login'], {
      queryParams: { from: 'user' },
      queryParamsHandling: 'merge'
    });
  }
}
