import { Injectable } from '@angular/core';
import {switchMap} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {StripeService} from "ngx-stripe";
import {AuthService} from "../../auth/services/auth.service";
import {DialogService, TandemDialogConfig} from "../../tandem-core/services/dialog.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  functionsPath = environment.functionsPath;
  constructor(private http: HttpClient,
              private stripeService: StripeService,
              private dialogService: DialogService,
              private auth: AuthService) { }

  openStripe() {
    this.auth.getIdToken().then(idToken => {
      if (idToken) {
        const config: TandemDialogConfig = {
          title: 'Hang On',
          content: 'Opening Stripe to process your payment. Please wait.',
          type: "loading",
        }
        const ref = this.dialogService.openModal2(config);
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        this.http.post<any>(`${environment.functionsPath}/createCheckoutSession`,{}, { headers }).pipe(
          switchMap(response => {
            console.log(environment.stripePublicKey)
            return this.stripeService.redirectToCheckout({ sessionId: response.session.id })
          })
        ).subscribe(result => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            this.dialogService.closeModal2();
            alert(result.error.message);
          }
        },
          error => {
            console.log('unexpected error');
            this.dialogService.closeModal2();
            this.dialogService.openMessageDialog('Unexpected Error', 'There was a problem in opening Stripe. Please refresh and try again, or contact support.', true);
          });
      }
    })
  }

  async validateAccessFromSession(sessionId: string): Promise<any> {
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      const params = new HttpParams().set('sessionId', sessionId);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/validatePaymentAndUpdateUser`, {}, { headers, params }).toPromise();

      if (result.error) {
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      console.error(error);
      throw error;
    }
  }

  async cancelSubscription(): Promise<any> {
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/cancelSubscription`, {}, { headers }).toPromise();

      if (result.error) {
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      console.error(error);
      throw error;
    }
  }

  async modifyAccess(request: AccessModificationRequest): Promise<any> {
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/modifyAccess`, request, { headers }).toPromise();

      if (result.error) {
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      console.error(error);
      throw error;
    }
  }

  async approveRevShareCoach(userId: string): Promise<{ message: string }> {
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/approveRevShareCoach`, {userId}, { headers }).toPromise();

      if (result.error) {
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      console.error(error);
      throw error;
    }
  }

  async transitionToIndividual(userId: string): Promise<any> {
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/transitionToIndividual`, {userId}, { headers }).toPromise();

      if (result.error) {
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      console.error(error);
      throw error;
    }
  }
}

export interface AccessModificationRequest {
  type: "individual" | "onBehalfOfUser";
  userId: string;
  coachId: string;
  outcome: "cancel" | "delete" | "resume";
  requestingActor: "coach" | "user";
}
