import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ModalRef} from "../../models/modal-ref";
import {AuthService} from "../../../auth/services/auth.service";
import {environment} from "../../../../../environments/environment";
import {VideoPlayerService, VideoType} from "../../services/video-player.service";
import {TandemUser} from "../../../auth/models/tandem-user";
import {UserService} from "../../../auth/user.service";

@Component({
  selector: 'tandem-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  public sanitizedVideoUrl: SafeResourceUrl | null = null;

  videoType: VideoType = 'userWelcomeIndividual';
  videoUrl?: string;
  user: TandemUser | null = null;

  public modalRef?: ModalRef


  constructor(private sanitizer: DomSanitizer,
              private auth: AuthService,
              private videoService: VideoPlayerService,
              private userService: UserService) {
  }


  ngOnInit(): void {
    console.log(this.videoUrl)
    if (this.videoUrl) {
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    } else {
      this.auth.$user.subscribe(user => {
        this.user = user;
        if (user?.coachId) {
          this.videoService.findByCoachAndType(user.coachId, this.videoType).subscribe(res => {
            let videoUrl = ''
            if (res) {
              videoUrl = res.url;
            } else {
              videoUrl = environment.defaultVideoConfig[this.videoType];
            }
            this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
          })
        } else if (user?.accountType === 'coach') {
          this.videoService.findByCoachAndType(user.uid, this.videoType).subscribe(res => {
            let videoUrl = ''
            if (res) {
              videoUrl = res.url;
            } else {
              videoUrl = environment.defaultVideoConfig[this.videoType];
            }
            this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
          })
        } else {
          this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.defaultVideoConfig[this.videoType]);
        }
      })
    }
  }

  // toggleFullScreen() {
  //   const videoElement = document.getElementById('yourVideoElementId'); // Your video or container element
  //
  //   if (!document.fullscreenElement) {
  //     if (videoElement?.requestFullscreen) {
  //       videoElement.requestFullscreen();
  //     } // Add else ifs for webkitRequestFullscreen, mozRequestFullScreen, msRequestFullscreen for broader browser support
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } // Add else ifs for webkitExitFullscreen, mozCancelFullScreen, msExitFullscreen for broader browser support
  //   }
  // }

  close() {
    if (this.user?.promptUserVideo || this.user?.promptCoachVideo) {
      this.user.promptUserVideo = false;
      this.user.promptCoachVideo = false;
      this.userService.update(this.user).then(res => this.modalRef?.close())
    } else {
      this.modalRef?.close();
    }
  }
}
