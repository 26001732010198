import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'tandem-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {

  @Input() label: string = '';
  @Input() description?: string;
  @Input() form: FormGroup = new FormGroup({});
  @Input() inputId: string = '';
  @Input() controlName: string = '';
  @Input() control: FormControl | undefined;
  @Input() needsButton = false;
  @Input() disabled = false;
  @Input() tooltipText?: string;

  @Output() checkboxToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  private alreadyChecked = false;
  constructor() {}

  ngOnInit() {
    if (!this.control) {
      this.control = this.form.get(this.controlName) as FormControl;
    }
    this.control.valueChanges.subscribe(newVal => {
      if (this.disabled) {
        if (this.alreadyChecked) {
          this.alreadyChecked = false;
        } else {
          this.alreadyChecked = true;
          this.control?.setValue(!newVal);
        }
      } else {
        this.checkboxToggled.emit(this.control!.value);
      }
    });
  }

  getControl() {
    return this.control as FormControl;
  }

  onToggle() {
    this.control?.setValue(!this.control?.value);
  }
}
