<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" style="z-index: 999999">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <ng-container *ngIf="config.type === 'confirm'">
          <div class="sm:flex sm:items-start">
            <div *ngIf="!config.hideImage" class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
            </div>
            <div class="mt-3 text-center items-center sm:items-start sm:ml-4 sm:mt-0 sm:text-left flex flex-col">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="confirm-title">{{config.title}}</h3>
              <div class="mt-2 w-3/4 sm:w-full">
                <p class="text-sm text-gray-500">{{config.content}}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 flex flex-col-reverse sm:mt-4 sm:flex-row justify-end">
            <ng-container *ngFor="let action of config.actions">
              <button [type]="action.role === 'cancel' ? 'button' : 'submit'"
                      class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm mt-3 sm:ml-3 sm:w-auto"
                      [ngClass]="{'bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50': action.role !== 'confirm' && action.role !== 'primary', 'bg-primary': action.role === 'primary', 'bg-red-600 hover:bg-red-500': action.role === 'confirm', 'text-white': ['confirm', 'primary'].includes(action.role)}"
                      (click)="callAction(action)">{{action.text}}</button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="config.type === 'success'">
          <div>
            <div *ngIf="!config.hideImage" class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="success-title">{{config.title}}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{config.content}}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <ng-container *ngFor="let action of config.actions">
              <button [type]="action.role === 'cancel' ? 'button' : 'submit'"
                      class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                      (click)="callAction(action)">{{action.text}}</button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="config.type === 'failure'">
          <div>
            <div *ngIf="!config.hideImage" class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="failure-title">{{config.title}}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{config.content}}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 flex flex-col-reverse sm:mt-4 sm:flex-row justify-end">
            <ng-container *ngFor="let action of config.actions">
              <button [type]="action.role === 'cancel' ? 'button' : 'submit'"
                      class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm mt-3 sm:ml-3 sm:w-auto"
                      [ngClass]="{'bg-primary text-white': action.role === 'contact', 'bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50': action.role === 'close'}"
                      (click)="callAction(action)">{{action.text}}</button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="config.type === 'info'">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="info-title">{{config.title}}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{config.content}}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <ng-container *ngFor="let action of config.actions">
              <button [type]="action.role === 'cancel' ? 'button' : 'submit'"
                      class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                      (click)="callAction(action)">{{action.text}}</button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="config.type === 'loading'">
          <div class="mt-5">
            <div class="mx-auto flex h-12 w-12 mb-5 items-center justify-center rounded-full">
              <tandem-loading-container></tandem-loading-container>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="loading-title">{{config.title}}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{config.content}}</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
