import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ModalRef} from "../../models/modal-ref";
import {environment} from "../../../../../environments/environment";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'tandem-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss']
})
export class SendEmailDialogComponent implements OnInit {

  contactForm: FormGroup = new FormGroup<any>({});
  responseReceived: boolean = false;
  isLoading: boolean = false;
  public modalRef?: ModalRef

  constructor(private auth: AuthService,
              private dialogService: DialogService,
              private fb: FormBuilder,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      email: [null, Validators.required],
      subject: [null, Validators.required],
      message: [null, Validators.required],
    })
    this.auth.$user.subscribe(user => {
      let email: string | null = null;
      if (user) {
        this.contactForm.get('email')?.setValue(user.email);
      }
    })
    this.contactForm.valueChanges.subscribe(res => console.log(this.contactForm));
  }

  onFormSubmit() {
    this.isLoading = true;
    this.http.post(`${environment.functionsPath}/sendContactEmail`, this.contactForm.value).subscribe(res => {
      this.responseReceived = true;
      this.isLoading = false;
      this.modalRef?.close();
      this.dialogService.openMessageDialog('Sent Email', `Your email has been sent to support. We should be back with you soon.`)
    })
  }

  onCancel() {
    this.modalRef?.close();
  }
}
