import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'tandem-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
        this.router.navigate(['/auth/profile'])
    }
}
