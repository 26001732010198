import {Inject, Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, CollectionReference} from "@angular/fire/compat/firestore";
import {AbstractDatabaseObject} from "../models/abstract-database-object";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Timestamp} from "firebase/firestore";
import firebase from "firebase/compat";
import Query = firebase.firestore.Query;
import {PageModel} from "../models/table-configuration";

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDatabaseService<T extends AbstractDatabaseObject> {

  protected collection: AngularFirestoreCollection<T>;

  protected constructor(@Inject('path') protected path: string,
                        protected afs: AngularFirestore) {
    this.collection = this.afs.collection(path);
  }

  public get(objectId: string): Observable<T> {
    return this.collection
      .doc<T>(objectId)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            const id = doc.payload.id;
            return {id, ...data};
          }
        })
      );
  }

  public list(): Observable<T[]> {
    return this.collection
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }



  public listByUserId(userId: string): Observable<T[]> {
    return this.afs.collection<T>(this.path, ref => ref.where('userId', '==', userId))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  /* TODO - this date logic is terrible. This function is only used by TT lists, we need to increase the day by 1 for anything coming from those datepickers */
  public listTrackingToolsByDateRange(userId: string, dateProperty: string, startDate?: Date, endDate?: Date, pageModel?: PageModel<T>) {
    return this.afs.collection<T>(this.path, ref => {
      let query: CollectionReference | Query = ref;

      query = query.where('userId', '==', userId);

      if (startDate) {
        // Reset to the start of the day in local time
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() + 1);
        // Convert to UTC
        const startDateUTC = new Date(startDate.toISOString());
        query = query.where(dateProperty, ">=", Timestamp.fromDate(startDateUTC));
      }

      if (endDate) {
        // Reset to the end of the day in local time
        endDate.setHours(23, 59, 59, 999);
        endDate.setDate(endDate.getDate() + 1);
        // Convert to UTC
        const endDateUTC = new Date(endDate.toISOString());
        query = query.where(dateProperty, "<=", Timestamp.fromDate(endDateUTC));
      }

      return this.paginateAndFilterQuery(query, pageModel);
    })
    .snapshotChanges()
    .pipe(
      map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as T;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  public listByPageModel(pageModel: PageModel<T>): Observable<T[]> {
    return this.afs.collection<T>(this.path, ref => {
      return this.paginateAndFilterQuery(ref, pageModel);
    })
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  add(object: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.collection.add(object).then(ref => {
        const newItem = {
          id: ref.id,
          ...(object as any)
        };
        resolve(newItem);
      });
    });
  }

  update(object: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const docRef = this.collection
        .doc<T>(object.id)
        .set(object)
        .then(() => {
          resolve({
            ...(object as any)
          });
        });
    });
  }

  delete(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const docRef = this.collection.doc<T>(id)
        .delete()
        .then(() => {
          resolve()
        });
    });
  }

  public getLatest(userId: string, latestProp: keyof T = 'dateCreated'): Observable<T | null> {
    return this.afs.collection<T>(this.path, ref => ref.where('userId', '==', userId)
      .orderBy(latestProp.toString(), 'desc').limit(1))
      .snapshotChanges()
      .pipe(
        map(changes => {
          if (changes.length > 0) {
            const data = changes[0].payload.doc.data() as T;
            data.id = changes[0].payload.doc.id;
            return data;
          } else {
            return null; // or throw an error or handle this case as per your requirement
          }
        })
      );
  }

  private paginateAndFilterQuery(query: CollectionReference | Query, pageModel?: PageModel<T>): CollectionReference | Query  {
    if (pageModel) {
      if (pageModel.sortProperty && pageModel.sortOrder) {
        query = query.orderBy(pageModel.sortProperty.toString(), pageModel.sortOrder);
      }
      if (pageModel.count) {
        query = query.limit(pageModel.count);
      }
      if (pageModel.lastDocument) {
        query = query.startAfter(pageModel.lastDocument);
      }
    }
    return query;
  }
}
