<div class="flex items-center mb-5">
  <!-- Enabled: "bg-primary", Not Enabled: "bg-gray-200" -->
  <span *ngIf="label" class="mr-4 text-sm" [id]="inputId">
    <span class="font-medium text-gray-900">{{label}}</span>
  </span>
  <button
    type="button"
    class=" relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
    [ngClass]="{'bg-gray-200': !control?.value, 'bg-primary': control?.value}"
    role="switch"
    aria-checked="false"
    (click)="onSwitch()"
    [attr.aria-labelledby]="inputId">
    <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out" [ngClass]="{'translate-x-5': control?.value}"></span>
  </button>
</div>
