import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Timestamp} from "firebase/firestore";
import {AuthService} from "../../../../auth/services/auth.service";
import {TandemUser} from "../../../../auth/models/tandem-user";
import {DialogService} from "../../../services/dialog.service";
import {FinancialPositionService} from "../../../../financial-positions/services/financial-position.service";
import {Asset} from "../../../../financial-positions/models/asset";
import {Liability} from "../../../../financial-positions/models/liability";
import {StatementItem} from "../../../models/statements/statement-item";
import {FinancialPosition} from "../../../../financial-positions/models/financial-position";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../auth/user.service";
import {AssetCategory} from "../../../../financial-positions/models/asset-category";
import {LiabilityCategory} from "../../../../financial-positions/models/liability-category";
import {StatementSection} from "../../../models/statements/statement-section";
import {StatementCategory} from "../../../models/statements/statement-category";
import {CashFlow} from "../../../../cash-flows/models/cash-flow";
import {SpendingPlan} from "../../../../spending-plans/models/spending-plan";

export interface Chat {
  userId: string;
  message: string;
  date: Timestamp;
  status: 'Read' | 'Delivered';
}

@Component({
  selector: 'tandem-comment-panel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.scss']
})
export class CommentPanelComponent implements OnInit {

  @Input() chats: Chat[] = [];

  @Input() chatType: 'financial-position' | 'cash-flow' | 'spending-plan' | 'asset' | 'liability' | 'revenue' | 'expense' = 'financial-position';

  @Input() financialPosition: FinancialPosition | null = null;
  @Input() cashFlow: CashFlow | null = null;
  @Input() spendingPlan: SpendingPlan | null = null;
  @Input() asset: Asset | null = null;
  @Input() assetCategory: AssetCategory | null = null;
  @Input() liability: Liability | null = null;
  @Input() liabilityCategory: LiabilityCategory | null = null;


  @Input() revenueSection: StatementSection | null = null;
  @Input() expenseSection: StatementSection | null = null;
  @Input() statementCategory: StatementCategory | null = null;
  @Input() statementItem: StatementItem | null = null;

  @Input() reviewedUserId: string | null = null;


  isLoaded = false;

  user: TandemUser | null = null;
  otherUser: TandemUser | null = null;
  currentUserId?: string;

  chatForm: FormGroup = new FormGroup<any>({});

  @Output() chatEvent = new EventEmitter<any>();

  constructor(private auth: AuthService,
              private dialogService: DialogService,
              private fb: FormBuilder,
              private fpService: FinancialPositionService,
              private userService: UserService) {
  }

  updateHandler(): void {
      if (this.chats.filter(chat => chat.message === this.chatForm.get('message')?.value)) {
        this.chatForm.get('message')?.reset();
      }
    }

  ngOnInit(): void {
    this.auth.$user.subscribe(user => {

      this.user = user;
      this.currentUserId = user?.id

      if (this.reviewedUserId) {
        this.userService.get(this.reviewedUserId).subscribe(otherUser => {
          this.otherUser = otherUser;
        })
      } else if (user?.coachId) {
        this.userService.get(user.coachId).subscribe(coach => {
          this.otherUser = coach;
        })
      }
    });

    this.chatForm = this.fb.group({message: [null, Validators.required]});
  }

  sendMessage() {
    switch (this.chatType) {
      case 'financial-position':
        this.chatEvent.emit({ type: 'fpMessage', data: this.chatForm.get('message')?.value });
        break;
      case 'cash-flow':
        this.chatEvent.emit({ type: 'cfMessage', data: this.chatForm.get('message')?.value });
        break;
      case 'spending-plan':
        this.chatEvent.emit({ type: 'spMessage', data: this.chatForm.get('message')?.value });
        break;
      case 'asset':
        this.chatEvent.emit({
          type: 'assetMessage',
          data: this.chatForm.get('message')?.value ,
          asset: this.asset
        });
        break;
      case 'liability':
        this.chatEvent.emit({
          type: 'liabilityMessage',
          data: this.chatForm.get('message')?.value ,
          liability: this.liability
        });
        break;
      case 'revenue':
        this.chatEvent.emit({
          type: 'statementItemMessage',
          data: this.chatForm.get('message')?.value ,
          statementSection: this.revenueSection,
          statementCategory: this.statementCategory,
          statementItem: this.statementItem,
        });
        break;
      case 'expense':
        this.chatEvent.emit({
          type: 'statementItemMessage',
          data: this.chatForm.get('message')?.value ,
          statementSection: this.expenseSection,
          statementCategory: this.statementCategory,
          statementItem: this.statementItem,
        });
        break;
    }
  }

  closeSidePanel() {
    this.dialogService.closeSidePanel();
  }

  getTTName(toolType: 'fp' | 'cf' | 'sp'): string {
    switch (toolType) {
      case 'fp':
        return this.user?.customTTMetadata?.fpName ? this.user.customTTMetadata.fpName : 'Financial Position';
      case 'cf':
        return this.user?.customTTMetadata?.cashFlowName ? this.user.customTTMetadata.cashFlowName : 'Cash Flow';
      case 'sp':
        return this.user?.customTTMetadata?.spendingPlanName ? this.user.customTTMetadata.spendingPlanName : 'Spending Plan';
    }
  }
}
