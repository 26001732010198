import { Injectable } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {TandemUser} from "../models/tandem-user";
import firebase from "firebase/compat/app";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService, TandemDialogConfig} from "../../tandem-core/services/dialog.service";
import {map, switchMap, take} from "rxjs/operators";
import {ModalRef} from "../../tandem-core/models/modal-ref";
import {ThemingService} from "../../tandem-core/services/theming.service";
import {LocalStorageService} from "../../tandem-core/services/local-storage.service";
import {HeaderService} from "../../tandem-core/services/header.service";
import {CoreService} from "../../tandem-core/services/core.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AttemptAuthDialogComponent} from "../components/attempt-auth-dialog/attempt-auth-dialog.component";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $user: BehaviorSubject<TandemUser | null> = new BehaviorSubject<TandemUser | null>(null);

  coachId: string | null = null;
  registrationMode: string | null = null;

  loadingMessage: string = '';
  userIsPaid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loadingDialog?: ModalRef;
  private creationLoadingDialog?: ModalRef;

  private serverPath = environment.functionsPath;

  userPermsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  $userPerms: Observable<void> = this.userPermsSubject.asObservable();

  authOpSucceeded = false;

  constructor(private auth: AngularFireAuth,
              private afs: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private localStorageService: LocalStorageService,
              private themingService: ThemingService,
              private headerService: HeaderService,
              private coreService: CoreService,
              private http: HttpClient
  ) {
    this.registrationMode = this.route.snapshot.paramMap.get('registrationMode');
    this.route.queryParams.subscribe(params => {
      if (params['registrationMode']) {
        this.registrationMode = params['registrationMode'];
      }
      if (params['coachId']) {
        this.coachId = params['coachId'];
      }
    });
    // this.coachId = this.route.snapshot.queryParamMap.get('coachId');
    // this.registrationMode = this.route.snapshot.queryParamMap.get('registrationMode');

    combineLatest([
      this.auth.authState,
      this.auth.authState.pipe(
        switchMap(firebaseUser => {
          this.coachId = this.route.snapshot.queryParamMap.get('coachId');
          if (firebaseUser) {
            return this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).valueChanges();
          } else {
            return [null];
          }
        })
      )
    ]).subscribe(([firebaseUser, tandemUser]) => {
      if (firebaseUser) {
        firebaseUser.getIdTokenResult().then(res => {
          this.userIsPaid.next(res.claims['paid'] === true || res.claims['isPaid']);
        })
      } else {
        this.userIsPaid.next(false);
      }
      if (firebaseUser && tandemUser) {
        // this.themingService.setTheme(tandemUser.theme || 'blue');
        // Do we need to set a coachID? (this is just after account creation with Google)
        this.coachId = this.coachId ? this.coachId : environment.defaultCoachId;
        if (tandemUser.checkForCoach && (this.coachId || (this.registrationMode === 'coachRevShare' || this.registrationMode === 'coachLeadGen'))) {
          if (this.coachId) {
            this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({
              coachId: this.coachId || environment.defaultCoachId,
              checkForCoach: false,
              accountType: 'user'
            }).then(res => {
              this.notifyCoachOfNewUser();
              this.afs.doc<TandemUser>(`users/${this.coachId}`).get().toPromise().then(coach => {
                let ttMetadata = coach?.data()?.customTTMetadata;
                if (ttMetadata) {
                  this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({customTTMetadata: ttMetadata}).then(result => {
                    this.authOpSucceeded = true;
                    this.dialogService.closeModal2();
                    this.updateUserTemplateData();
                  })
                } else {
                  this.authOpSucceeded = true;
                  this.dialogService.closeModal2();
                  this.updateUserTemplateData();
                }
              })
            });
          } else if (this.registrationMode === 'coachRevShare' || this.registrationMode === 'coachLeadGen') {
            this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({
              checkForCoach: false,
              accountType: 'coach',
              coachType: this.registrationMode
            }).then(res => {
              this.authOpSucceeded = true;
              this.dialogService.closeModal2();
              this.updateUserTemplateData();
              if (this.registrationMode === 'coachRevShare') {
                this.notifyAdminsOfNewCoach();
              }
            });
          }
          this.clearLoadingDialogs();
          this.$user.next(tandemUser);
        } else {
          this.$user.next(tandemUser);
          this.authOpSucceeded = true;
          this.dialogService.closeModal2();
          if (!tandemUser.verified) {
            if (firebaseUser.emailVerified) {
              this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({verified: true}).then(res => {
                this.authOpSucceeded = true;
                this.dialogService.closeModal2();
                this.loadingDialog?.close();
                this.loadingDialog = undefined;
                this.clearLoadingDialogs();
                if (this.router.url !== '/auth/profile') {
                  this.router.navigate(['dashboard'], { queryParamsHandling: 'preserve' });
                }
              })
            } else {
              this.clearLoadingDialogs();
              this.router.navigate(['/auth/email-not-verified'])
              this.authOpSucceeded = true;
              this.dialogService.closeModal2();
            }
          } else {
            if (tandemUser.firstLogin || tandemUser.needsTokenRefresh) {
              firebaseUser.getIdToken(true).then(idToken => {
                this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({
                  firstLogin: false,
                  needsTokenRefresh: false
                }).then(res => {
                  this.authOpSucceeded = true;
                  this.dialogService.closeModal2();
                  this.userPermsSubject.next();
// Check if the current route contains 'verify-email'
                  const currentRoute = this.route.snapshot.url.map(segment => segment.path).join('/');
                  if (!currentRoute.includes('verify-email') && !currentRoute.includes('payment-success')) {
                    this.authOpSucceeded = true;
                    this.dialogService.closeModal2();
                    this.loadingDialog?.close();
                    this.loadingDialog = undefined;
                    this.clearLoadingDialogs();
                    if (this.router.url !== '/auth/profile') {
                      this.router.navigate(['dashboard'], {queryParamsHandling: 'preserve'});
                    }
                  }
                });
              });
            }
            if (tandemUser.promptPaymentSuccess) {
              this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({
                promptPaymentSuccess: false
              }).then(updated => {
                this.userPermsSubject.next();
                this.clearLoadingDialogs();
                if (this.router.url !== '/auth/profile') {
                  this.router.navigate(['dashboard'], {queryParamsHandling: 'preserve'}).then(navigated => {

                    let message = '';
                    if (tandemUser.accountType === 'user') {
                      message = `Congratulations on unlocking your premium account, you now have access to all features on the platform. If you need a refresh, click the "Need a Refresh" link on your dashboard for a comprehensive demo.`;
                    } else {
                      message = `An administrator has granted you coaching privileges. You now have access to all features on the platform.`;
                    }
                    const config: TandemDialogConfig = {
                      title: tandemUser.accountType === 'user' ? 'Premium Unlocked!' : 'Access Granted',
                      content: message,
                      type: 'success',
                      actions: [
                        {
                          text: 'Okay',
                          callback: () => this.dialogService.closeModal2(),
                          role: 'close'
                        }
                      ]
                    };
                    this.dialogService.openModal2(config);
                  })
                }
              })
            }
          }
        }
      } else {
        this.$user.next(null);
      }
    });
  }

  private notifyCoachOfNewUser() {
    this.getIdToken().then(idToken => {
      if (idToken) {
        // const ref = this.dialogService.openLoadingDialog('Hang On', 'Opening Stripe to process your payment. Please wait.');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        this.http.get<any>(`${environment.functionsPath}/notifyCoachOfNewUser`, { headers }).subscribe(result => {
            if (result.error) {
              // alert(result.error.message);
            }
          },
          error => {
            // ref.close();
            // this.dialogService.openMessageDialog('Unexpected Error', 'There was a problem in opening Stripe. Please refresh and try again, or contact support.', true);
          });
      }
    })
  }

  private updateUserTemplateData() {
    this.getIdToken().then(idToken => {
      if (idToken) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        this.http.get<any>(`${environment.functionsPath}/manageTemplateForUser`, { headers }).subscribe(result => {
            if (result.error) {
            }
          },
          error => {
            console.log('unexpected error');
          });
      }
    })
  }

  private notifyAdminsOfNewCoach() {
    this.getIdToken().then(idToken => {
      if (idToken) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
        this.http.get<any>(`${environment.functionsPath}/notifyAdminsOfNewCoach`, { headers }).subscribe(result => {
            if (result.error) {
            }
          },
          error => {
            console.log('unexpected error');
          });
      }
    })
  }

  public reloadUser() {
    this.auth.authState.pipe(
      take(1)
    ).subscribe(firebaseUser => {
      firebaseUser?.getIdToken(true).then(token => {
        const base64Url = token.split('.')[1]; // Get the payload part of the token
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const payload = JSON.parse(jsonPayload);

        // Check if the email is verified
        if (payload.email_verified) {
          this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({verified: true}).then(res => {
            this.clearLoadingDialogs();
            if (this.router.url !== '/auth/profile') {
              this.router.navigate(['dashboard'], {queryParamsHandling: 'preserve'});
            }
          })
        } else {
        }
      });
    })
  }

  public async registerWithEmailPass(email: string, pass: string, coachId: string | null) {
    let config: TandemDialogConfig = {
      title: 'Creating Account',
      content: 'Creating your account. Please don\'t refresh during this process. Thanks!',
      type: 'loading'
    };
    this.dialogService.openModal2(config);
    setTimeout(() => {
      if (!this.authOpSucceeded) {
        this.deleteCurrentUser();
        this.dialogService.closeModal2();
        this.dialogService.openConfirmDialog('Unexpected Error', `Whoops, looks like there was a problem creating your account. Please try again or contact support if the issue persists.`, '', 'Close', true, false, false, true);
      }
    }, 30000);
    // // this.creationLoadingDialog = this.dialogService.openModal(AttemptAuthDialogComponent);
    // this.creationLoadingDialog.afterClosed().subscribe(failed => {
    //   if (failed) {
    //           this.deleteCurrentUser();}
    // })
    //this.loadingDialog = this.dialogService.openLoadingDialog('Creating User', 'Please wait, we are processing your information');
    try {
      const r = await this.auth.createUserWithEmailAndPassword(email, pass);
    } catch (error: any) {
      let errorDisplay = this.getErrorDisplay(error.code);
      let errorMessage = '';
      this.clearLoadingDialogs();
      // let config: TandemDialogConfig = {
      //   title: 'Unexpected Error',
      //   content: errorMessage,
      //   actions: [
      //     {
      //       text: 'Contact Support',
      //       role: 'contact',
      //       callback: () => this.co
      //     }
      //   ]
      // }
      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }    }
  }

  public async onGoogleLogin(method: 'register' | 'login') {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.auth.signInWithPopup(provider).then(signedIn => {
         if (signedIn.additionalUserInfo?.isNewUser) {
           let config: TandemDialogConfig = {
             title: 'Creating Account',
             content: 'Creating your account. Please don\'t refresh during this process. Thanks!',
             type: 'loading'
           };
           this.dialogService.openModal2(config);
           setTimeout(() => {
             if (!this.authOpSucceeded) {
               this.deleteCurrentUser();
               this.dialogService.closeModal2();
               this.dialogService.openConfirmDialog('Unexpected Error', `Whoops, looks like there was a problem creating your account. Please try again or contact support if the issue persists.`, '', 'Close', true, false, false, true);
             }
           }, 30000);
           // this.creationLoadingDialog = this.dialogService.openModal(AttemptAuthDialogComponent);
         // this.creationLoadingDialog.afterClosed().subscribe(failed => {
         //     if (failed) {
         //       this.deleteCurrentUser();
         //     }
         //   })
         }
      }).catch(err => {
        throw err;
      });
      // if (method === 'register') {
      //   this.loadingDialog = this.dialogService.openLoadingDialog(`Creating User`, 'Please wait, we are processing your information');
      // }
      // this.loadingDialog?.close();
      // this.loadingDialog = undefined;
    } catch (e: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;

      let errorDisplay = this.getErrorDisplay(e.code);

      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }
      throw e;
    }
  }

  public async signInWithEmailPass(email: string, pass: string) {
    this.loadingDialog = this.dialogService.openLoadingDialog(`Signing In`, 'Please wait, we are processing your information');
    try {
      const res = await this.auth.signInWithEmailAndPassword(email, pass);
      this.loadingDialog?.close();
      this.loadingDialog = undefined;
      const res_1 = undefined;
      this.clearLoadingDialogs();
      if (this.router.url !== '/auth/profile') {
        this.router.navigate(['/dashboard'], {queryParamsHandling: 'preserve'});
      }
    } catch (err: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;
      let errorDisplay = this.getErrorDisplay(err.code);
      if (errorDisplay.message && errorDisplay.title) {
        this.dialogService.openConfirmDialog(errorDisplay.title, errorDisplay.message, undefined, 'Close', true, false, false, true);
      }
    }
  }

  // TODO change this to use user service instead?
  public getUser(userId: string) {
    return this.afs.collection<TandemUser>('users').doc<TandemUser>(userId).snapshotChanges().pipe(map(doc => {
      if (doc.payload.exists) {
        const data = doc.payload.data() as any;
        const id = doc.payload.id;
        return {id, ...data};
      }
    }))
  }

  public verifyEmail(oobCode: string) {
    return this.auth.applyActionCode(oobCode);
    // .then(() => {
    //   // make a call to my backend that updates the user document's verification status (function will check if the user is actually verified)
    //   this.functions.httpsCallable('updateVerificationStatus').call(this,{ userId: 'user_id_here' }).subscribe(result => {
    //     console.log('Successfully updated document!');
    //   }, error => {
    //     console.error(error);
    //   })
    //   // this.http.
    // })
    // .catch(error => {
    // });
  }

  public resetPassword(oobCode: string, newPassword: string) {
    return this.auth.confirmPasswordReset(oobCode, newPassword);
  }

  public changePassword() {

  }

  public changeEmail() {

  }

  public resendEmailVerification(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.serverPath}/resendEmailVerification`, {params: params});
    // return this.functions.httpsCallable('resendVerificationEmail').call(this, {email: email});
  }

  public getCurrentUser() {
    return this.auth.currentUser;
  }

  onLogout(withReload = true) {
    this.localStorageService.clearAllStorage();
    this.coreService.clearAllSubscriptions();
    return this.auth.signOut()
      .then(res => this.router.navigate(['/auth/login']).then(res => {
        this.themingService.setTheme({
          primaryColor: environment.defaultTheme,
          bodyFont: environment.defaultBodyFont,
          headerFont: environment.defaultHeaderFont
        });
        this.headerService.resetConfig();
        this.$user.next(null);
        location.reload();
        this.dialogService.showAlert({
          title: 'Logged Out',
          content: 'Remember, we\'re here 24/7 for all your financial needs. Come back soon!',
          dismissTime: 7500,
          position: 'bottom'
        })
      }))
  }

  logoutAndRedirect() {
    this.localStorageService.clearAllStorage();
    this.coreService.clearAllSubscriptions();
    console.log('signing out')
    return this.auth.signOut().then(loggedOut => {
      console.log('signed out!');

      window.location.href = `${environment.defaultDomain}/login`;
      console.log('routed?')
    })
  }

  public async getIdToken() {
    const user = await this.auth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }

  sendPasswordResetEmail(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.serverPath}/sendPasswordReset`, {params: params});
  }

  private getErrorDisplay(errorCode: string): {title?: string; message?: string;} {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return {
          title: 'Email Already in Use',
          message: "Looks like the email address you entered is already in use by another account. Please use a different email address to create an account or sign in with the email you entered."
        }
      case 'auth/invalid-email':
        return {
          title: 'Invalid Email',
          message: "Whoops, looks like that email is not a valid email address. Please make sure your email is correct and try again. If you need to create an account, click the 'Sign Up Here' link."
        }
      case 'auth/operation-not-allowed':
        return {
          title: 'Operation Not Allowed',
          message: "This sign-in method isn't currently enabled. Please check with your administrator or visit the Firebase Console under the Auth tab to enable it."
        }
      case 'auth/weak-password':
        return {
          title: 'Weak Password',
          message: "Looks like you need a stronger password. Make sure your password has at least one capital letter, one special symbol, and is a minimum of 8 characters long."
        }
      case 'auth/account-exists-with-different-credential':
        return {
          title: 'Wrong Sign in Method',
          message: "It looks like an account with this email already exists but was registered using a different sign-up method. Please try signing in with the method you used to sign up."
        }
      case 'auth/auth-domain-config-required':
        return {
          title: 'Domain Configuration Error',
          message: "Oops! We're missing some important configuration details to proceed. Please ensure that the 'authDomain' configuration is correctly set. You can find guidance on this in the Firebase Console under your project settings."
        }
      case 'auth/cancelled-popup-request':
        return {
          // title: 'title',
          // message: "We noticed you've triggered several sign-in popups. To keep the site running smoothly, we only handle one sign-in popup at a time. Please try again, and remember, one popup at a time works best!"
        }
      case 'auth/popup-closed-by-user':
        return {
          // title: 'title',
          // message: "It looks like the sign-in popup was closed before completing the sign-in process. If you closed the popup by mistake, please try signing in again."
        }
      case 'auth/user-not-found':
        return {
          title: 'User Not Found',
          message: "Whoops, it looks like you don\'t have a registered account with us! Please verify the information you entered is correct and try again. If you need to create an account, click the \'Sign Up Here\' link."
        }
      case 'auth/wrong-password':
        return {
          title: 'Wrong Password',
          message: "Whoops, looks like the password you entered is incorrect. Please try again."
        }
      case 'auth/invalid-login-credentials':
        return {
          title: 'Invalid Credentials',
          message: "Whoops, looks like the email or password you entered is incorrect. Please try again."
        }
      default:
        return {
          title: 'Unexpected Error',
          message: "Whoops! An unexpected error occurred, please try again."
        }
    }
  }

  clearLoadingDialogs() {
    this.loadingDialog?.close();
    this.creationLoadingDialog?.close();
    this.loadingDialog = undefined;
    this.creationLoadingDialog = undefined;
  }

  async deleteCurrentUser() {
    const user = await this.auth.currentUser;
    if (user) {
      user.delete().then(() => {
        console.log('User deleted successfully');
      }).catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          this.onLogout(false).then(loggedOut => {
            this.dialogService.openMessageDialog('Sign In Again', `It looks like something went wrong on our end. Please try signing in again. If the error persists, please contact support`, true)
          })
        } else {
          console.error(error)
        }
      });
    }
  }

  async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    const user = await this.auth.currentUser;
    if (user) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email as string,
        currentPassword
      );

      try {
        await user.reauthenticateWithCredential(credential);
        await user.updatePassword(newPassword);
      } catch (error: any) {
        console.log(error)
        throw new Error(error.message);
      }
    } else {
      throw new Error('No user is currently signed in.');
    }
  }
}
