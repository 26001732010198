<div class="flex flex-col items-center justify-center">
  <div class="flex flex-col w-full h-full gap-20">
    <h1 class="text-28">Verify Credentials</h1>
    <tandem-form-input [controlName]="'email'"
                       [form]="emailForm"
                       label="New Email"
                       [inputId]="'email'"
                       placeholder="New Email"
                       [inputType]="'email'"></tandem-form-input>
    <tandem-form-input [controlName]="'password'"
                       [form]="emailForm"
                       label="Password"
                       [inputId]="'password'"
                       placeholder="Password"
                       [inputType]="'password'"></tandem-form-input>
    <div class="flex flex-row justify-center gap-15">
      <tandem-button theme="outline" color="red" [onClick]="close.bind(this)">Cancel</tandem-button>
      <tandem-button theme="outline" color="primary" [onClick]="onSubmit.bind(this)" [disabled]="emailForm.invalid">Verify</tandem-button>
    </div>
  </div>
</div>
