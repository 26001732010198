import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class ModalRef {

  public data: any;
  private afterClosedSubject: Subject<any> = new Subject<any>();

  constructor(data?: any) {
    this.data = data;
  }

  close(result?: any): void {
    this.afterClosedSubject.next(result);
  }

  // listenToEscapeKey() {
  //   document.addEventListener('keydown',
  //     this.es)
  // }
  afterClosed() {
    return this.afterClosedSubject.asObservable();
  }
}
