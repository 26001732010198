import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import 'firebase/compat/firestore';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {combineLatest, filter, Subscription} from 'rxjs';
import {DialogService, TandemDialogConfig,} from './modules/tandem-core/services/dialog.service';
import {HeaderConfig, HeaderService,} from './modules/tandem-core/services/header.service';
import {AuthService} from "./modules/auth/services/auth.service";
import {TandemUser} from "./modules/auth/models/tandem-user";
import {slideInAnimation} from "./modules/tandem-core/animations/route-animations";
import {map} from "rxjs/operators";
import {ThemingService} from "./modules/tandem-core/services/theming.service";
import {environment} from "../environments/environment";
import {SidenavState} from "./components/nav/sidenav2/sidenav2.component";
import {LocalStorageService} from "./modules/tandem-core/services/local-storage.service";
import {NotificationType, TandemNotification} from "./modules/tandem-core/models/tandem-notification";
import {NotificationService} from "./modules/tandem-core/services/notification.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidePanelContent', { read: ViewContainerRef }) sidePanelContainer?: ViewContainerRef;


  user: TandemUser | null = null;
  displayHeader = false;

  displaySidenav: boolean = true;
  displayNavbar: boolean = false;

  screenIsSmall = false;

  sidenavState: SidenavState = 'collapsed';

  disableAllNav = false;
  showProfileDropdown = false;

  bannerText?: string;

  notifications: TandemNotification[] = [
    // {
    //   id: '1',
    //   userId: 'user1',
    //   sourceUserName: 'John Doe',
    //   date: Timestamp.now(),
    //   content: 'You have a new message',
    //   isRead: false,
    //   type: NotificationType.DirectMessage,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // {
    //   id: '2',
    //   userId: 'user1',
    //   sourceUserName: 'Jane Smith',
    //   date: Timestamp.now(),
    //   content: 'Jane mentioned you in a comment',
    //   isRead: false,
    //   type: NotificationType.MentionInComment,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // {
    //   id: '3',
    //   userId: 'user1',
    //   sourceUserName: 'System',
    //   date: Timestamp.now(),
    //   content: 'Your account has been verified',
    //   isRead: true,
    //   type: NotificationType.SystemAlert,
    //   dateCreated: Timestamp.now(),
    //   dateModified: Timestamp.now(),
    // },
    // Add more hardcoded notifications as needed
  ];

  showNotificationsDropdown = false;

  navSections: {
    label?: string;
    items: { label: string; routerLink: string }[];
    authProp: string
  }[] = [
    {
      label: 'Tracking Tools',
      items: [
        {
          label: 'Financial Positions',
          routerLink: 'financial-positions'
        },
        {
          label: 'Cash Flows',
          routerLink: 'cash-flows'
        },
        {
          label: 'Spending Plans',
          routerLink: 'spending-plans'
        },
      ],
      authProp: 'trackingTools'
    },
    {
      label: 'Calculators',
      items: [
        {
          label: 'Debt Payoff',
          routerLink: 'calculators/debt-payoff'
        },
        {
          label: 'Investment',
          routerLink: 'calculators/investment'
        },
        {
          label: 'Debt Vs. Invest',
          routerLink: 'calculators/debt-vs-invest'
        },
      ],
      authProp: 'calculators'
    },
    {
      label: 'Coaching',
      items: [
        {
          label: 'User Management',
          routerLink: 'coaching/user-management'
        },
      ],
      authProp: 'isCoach'
    },
    {
      label: 'Admin',
      items: [
        {
          label: 'Coach Management',
          routerLink: 'admin/approve-coaches'
        }
      ],
      authProp: 'isAdmin'
    }
  ]
  headerConfig?: HeaderConfig;
  disableHeader = false;

  fpProp = 'Financial Positions';
  cfProp = 'Cash Flows';
  spProp = 'Spending Plans';

  currentEnvironment = environment.environmentName;

  displayDefaultLogo = true;

  currentDomain: string = 'tandemfinance.app';

  private sidePanelSubscription?: Subscription;

  whiteIconPath: string = environment.whiteIconPath;
  primaryIconPath: string = environment.primaryIconPath;
  logoPath: string = environment.logoPath;
  whiteLogoPath: string = environment.whiteLogoPath;


  constructor(
    private router: Router,
    private dialogService: DialogService,
    private headerService: HeaderService,
    private themingService: ThemingService,
    private auth: AuthService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  ngOnInit(): void {
    this.themingService.checkForThemeUpdate(window.location.host);

    if (!environment.production) {
      this.bannerText = `${environment.environmentName} - 0.7.0-alpha`;
    }
    // this.checkScreenSize();
    combineLatest([
      this.auth.$user,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd), // only consider NavigationEnd events
        map(() => this.router.url) // extract the URL
      )
    ]).subscribe(([user, currentUrl]) => {
      this.user = user;
      if (this.user?.primaryColor) {
        this.themingService.setTheme({
          primaryColor: this.user.primaryColor,
          bodyFont: this.user.bodyFont || environment.defaultBodyFont,
          headerFont: this.user.headerFont || environment.defaultHeaderFont
        });
      }
      if (this.user?.logoURL) {
        this.whiteLogoPath = this.user.logoURL;
      }
      if (this.user?.iconURL) {
        this.whiteIconPath = this.user.iconURL;
      }
      this.navSections.forEach(section => {
        section.items.forEach(item => {
          if (item.label === 'Financial Positions') {
            item.label = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
            this.fpProp = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
          } else if (item.label === 'Cash Flows') {
            item.label = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
            this.cfProp = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
          } else if (item.label === 'Spending Plans') {
            item.label = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';
            this.spProp = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';
          }
        });
      });
      if (this.user) {
        this.notificationService.getNotificationsForUser(this.user.uid).subscribe(notifications => {
          this.notifications = notifications;
        })
      }
      if (!this.user) {
        this.themingService.setTheme({
          primaryColor: environment.defaultTheme,
          bodyFont: environment.defaultBodyFont,
          headerFont: environment.defaultHeaderFont
        });
      }

      this.disableAllNav = !user || (currentUrl.includes('auth/email-not-verified') || currentUrl.includes('auth/additional-info'));
      this.displayHeader = !!user || (!currentUrl.includes('auth/email-not-verified') && !currentUrl.includes('auth/additional-info'));
      this.displayNavStack = !!user && (!currentUrl.includes('auth/email-not-verified') && !currentUrl.includes('auth/additional-info') && !currentUrl.includes('stripe/payment-success') && !currentUrl.includes('file-management/preview'));
      this.displayDefaultLogo = this.route.snapshot.paramMap.get('coachId') === null;
    });

    this.headerService.getConfig().subscribe(config => {
      this.headerConfig = config;
    })

    this.sidePanelSubscription = this.dialogService.sidePanelContent$.subscribe(
      (content) => {
        this.showSidePanel = !!content;
      }
    );

    // Initial check for screen size
    // this.checkScreenSize();
  }

  // checkScreenSize(): void {
  //   if (!this.disableAllNav) {
  //     this.screenIsSmall = window.innerWidth < 1440;
  //     this.displaySidenav = window.innerWidth >= 1440;
  //     this.displayNavbar = window.innerWidth < 1440;
  //     if (this.screenIsSmall) {
  //       this.sidenavState = "closed";
  //     } else {
  //       this.sidenavState = 'collapsed';
  //     }
  //   } else {
  //     this.displaySidenav = false;
  //     this.displayHeader = false;
  //     this.displayNavbar = false;
  //   }
  // }

  ngAfterViewInit() {
    if (!!this.sidePanelContainer) {
      this.dialogService.setViewContainerRef(this.sidePanelContainer);
    }
  }

  ngOnDestroy() {
    this.sidePanelSubscription?.unsubscribe();
  }

  protected readonly environment = environment;
  showTWSidebar = false;
  displayNavStack = false;
  showTooltip: string | null = null;
  showSidePanel = true;

  logout() {

    if (this.localStorageService.getItem('createFinancialPosition')
      || this.localStorageService.getItem('createCashFlow')
      || this.localStorageService.getItem('createSpendingPlan')) {
      let modalConfig: TandemDialogConfig = {
        title: 'Sign Out',
        type: 'confirm',
        content: `Are you sure you want to sign out of your account? You will lose progress on any unsaved Tracking Tools.`,
        actions: [
          {
            text: 'Cancel',
            role: 'cancel',
            callback: () => {
              this.dialogService.closeModal2();
            }
          },
          {
            text: 'Sign Out',
            role: 'confirm',
            callback: () => {
              this.dialogService.closeModal2();
              this.auth.onLogout(true);
            }
          }
        ]
      }
      this.dialogService.openModal2(modalConfig);
    } else {
      this.auth.onLogout(true);
    }

  }

  closeDropdown() {
    this.showProfileDropdown = false;
  }

  toggleDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown;
  }

  onCollapse() {
    this.sidenavState = 'collapsed';
  }

  toggleSidenavState() {
    if (this.sidenavState === 'collapsed') {
      this.sidenavState = 'expanded';
    } else {
      this.sidenavState = 'collapsed';
    }
  }

  toggleProfileDropdown() {
    this.showProfileDropdown = !this.showProfileDropdown;
    this.showNotificationsDropdown = false;
  }

  toggleNotificationsDropdown() {
    this.showNotificationsDropdown = !this.showNotificationsDropdown;
    this.showProfileDropdown = false;
  }

  closeDropdowns() {
    this.showProfileDropdown = false;
    this.showNotificationsDropdown = false;
  }

  getUnreadNotificationsCount(): number {
    return this.notifications.filter(n => !n.isRead).length;
  }

  markAsRead(notification: TandemNotification) {
    if (notification.link) {
      this.router.navigate([notification.link]).then(res => {
        notification.isRead = true;
        this.notificationService.update(notification).then(updated => {

        })
        this.closeDropdowns();

      })
    }
    // Here you would typically update the notification in your backend
  }

  // markAsRead(notification: TandemNotification) {
  //   if (notification.link) {
  //
  //     if (notification.type === NotificationType.MentionInComment) {
  //       this.navigateToNotificationLink(notification.link);
  //     } else {
  //       this.router.navigate([notification.link]).then(res => {
  //         notification.isRead = true;
  //         this.notificationService.update(notification).then(updated => {
  //
  //         })
  //         this.closeDropdowns();
  //
  //       })
  //     }
  //   }
  //   // Here you would typically update the notification in your backend
  // }
  // TODO WIP
  // navigateToNotificationLink(notification: TandemNotification) {
  //   try {
  //     const routeConfig = JSON.parse(serializedRoute);
  //     if (routeConfig.reviewedUser) {
  //       this.router.navigate(['/financial-positions', routeConfig.financialPositionId], {
  //         queryParams: { assetCategory: routeConfig.assetCategory, asset: routeConfig.asset }
  //       }).then(res => this.resolveNotification(notification));
  //     } else {
  //       this.router.navigate(['/coaching/user-management', routeConfig.userId, 'financial-positions', routeConfig.financialPositionId], {
  //         queryParams: { assetCategory: routeConfig.assetCategory, asset: routeConfig.asset }
  //       }).then(res => this.resolveNotification(notification));
  //     }
  //
  //   } catch (error) {
  //     console.error('Error parsing notification link:', error);
  //   }
  // }
  //
  // resolveNotification(notification: TandemNotification) {
  //
  // }

  closeSidePanel() {
    this.dialogService.closeSidePanel();
  }

  markAllNotificationsAsRead() {
    if (this.user) {
      this.notificationService.markAllNotificationsAsRead(this.user.uid).then(r => {});
    }
  }

  deleteAllNotifications() {
    if (this.user) {
      this.notificationService.deleteAllNotificationsForUser(this.user.uid).then(r => {this.showNotificationsDropdown = false;});
    }
  }

  deleteNotification(notification: TandemNotification) {
    if (notification.id) {
      this.notificationService.delete(notification.id).then();
    }
  }
}
