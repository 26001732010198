import {AfterViewInit, Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, debounceTime, fromEvent, Subject, Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {AngularFirestore} from "@angular/fire/compat/firestore";


export interface ThemingConfig {
  headerFont: string;
  bodyFont: string;
  iconURL: string;
  logoURL: string;
  primaryColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemingService implements OnInit, OnDestroy, AfterViewInit {

  // public domainMap: Map<string, {logoPath: string; iconPath: string; whiteLogoPath: string; whiteIconPath: string}> = new Map<string, {logoPath: string; iconPath: string; whiteLogoPath: string; whiteIconPath: string}>([
  //   ['localhost', {
  //   logoPath: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/73/Fat_bastard.jpeg/220px-Fat_bastard.jpeg',
  //   iconPath: '',
  //   whiteLogoPath: '',
  //   whiteIconPath: '',
  //   }]
  // ]);

  domainMap: Map<string, string> = new Map<string, string>([
    ['localhost:4200', 'bDjbMEJCKvOkdiD2giuMwMtceDs1']
  ])

  public themes = {
    blue: 'rgb(70,96,234)',
    prevost: 'rgb(191, 142, 68)',
    purple: 'rgb(108,58,214)',
    'spring-green': 'rgb(98,154,26)',
    'sea-green': 'rgb(26,127,109)',
    teal: 'rgb(6,120,155)',
    forest: 'rgb(39,107,33)',
    navy: 'rgb(35,85,131)',
    orange: 'rgb(193,74,23)',
    fuscia: 'rgb(179,34,215)',
    'money-life': 'rgb(125, 106, 145)',
    'ernst-financial': 'rgb(33,45,31)',
    'lwilliam': 'rgb(0,118,209)',
    'womenOfColor': 'rgb(236,14,114)'
  };

  public colorMap = {
    green: '#0b7b2a',
    red: '#d20d2e',
    faded: '#636c7a'
  }

  brandingConfig: BehaviorSubject<ThemingConfig> = new BehaviorSubject({
    headerFont: environment.defaultHeaderFont,
    bodyFont: environment.defaultBodyFont,
    primaryColor: environment.defaultTheme,
    logoURL: environment.logoPath,
    iconURL: environment.whiteIconPath,
  })


  selectedTheme: BehaviorSubject<string> = new BehaviorSubject<string>(this.themes.blue)
  themeNames = [
    'blue',
    'purple',
    'spring-green',
    'sea-green',
    'teal',
    'forest',
    'navy',
    'orange',
    'fuscia',
    'money-life'
  ];

  screenSize: BehaviorSubject<'sm' | 'md' | 'lg' | 'xl' | 'xxl'> = new BehaviorSubject<"sm" | "md" | "lg" | "xl" | "xxl">('sm');
  private resizeSubscription: Subscription | null = null;

  constructor(private afs: AngularFirestore) {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(250)) // debounce it to prevent high frequency updates
      .subscribe(() => {
        this.screenSize.next(this.getScreenSize())
      })
  }

  ngAfterViewInit(): void {
    this.screenSize.next(this.getScreenSize());
  }

  ngOnInit(): void {
    }

  ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();
    }

  // getLogoPath() {
  //   return this.domainMap.get(window.location.hostname)?.logoPath || 'assets/svg/logo/full_blue.svg';
  // }

  getScreenSize(): 'sm' | 'md' | 'lg' | 'xl' | 'xxl' {
    const width = window.innerWidth;

    if (width <= 420) return 'sm';
    else if (width > 420 && width <= 768) return 'md';
    else if (width > 768 && width <= 1024) return 'lg';
    else if (width > 1024 && width <= 1440) return 'xl';
    else return 'xxl';
  }

  getPrimaryRGB() {
    return this.selectedTheme.value;
  }

  setTheme(theme: { primaryColor: string; headerFont: string; bodyFont: string; }) {
    this.loadFontsWithFallback(theme.headerFont, theme.bodyFont).then(([loadedHeaderFont, loadedBodyFont]) => {
      console.log(theme);
      const style = document.createElement('style');
      style.textContent = `
        :root {
          --primary: ${theme.primaryColor} !important;
          --font-family-header: ${loadedHeaderFont}, sans-serif;
          --font-family-body: ${loadedBodyFont}, sans-serif;
        }
      `;
      document.head.appendChild(style);
      this.selectedTheme.next(theme.primaryColor);
    });
  }

  private loadFontsWithFallback(headerFont: string, bodyFont: string, fallbackFont: string = 'Arial'): Promise<[string, string]> {
    return Promise.all([
      this.loadFont(headerFont, fallbackFont),
      this.loadFont(bodyFont, fallbackFont)
    ]);
  }

  private loadFont(fontName: string, fallbackFont: string): Promise<string> {
    return new Promise((resolve) => {
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/\s+/g, '+')}`;
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      const testElement = document.createElement('span');
      testElement.style.position = 'absolute';
      testElement.style.visibility = 'hidden';
      testElement.style.fontSize = '40px';
      testElement.style.fontFamily = `"${fontName}", ${fallbackFont}`;
      testElement.textContent = 'Test Font Loading';
      document.body.appendChild(testElement);

      setTimeout(() => {
        if (this.isFontLoaded(testElement, fontName, fallbackFont)) {
          resolve(fontName);
        } else {
          console.warn(`Font ${fontName} failed to load. Using fallback: ${fallbackFont}`);
          resolve(fallbackFont);
        }
        document.body.removeChild(testElement);
      }, 100); // Adjust timeout as needed
    });
  }

  private isFontLoaded(element: HTMLElement, fontName: string, fallbackFont: string): boolean {
    const fontWidthWithFallback = element.offsetWidth;
    element.style.fontFamily = fallbackFont;
    const fontWidthWithoutFallback = element.offsetWidth;
    return fontWidthWithFallback !== fontWidthWithoutFallback;
  }

  // private loadGoogleFonts(...fonts: string[]) {
  //   const uniqueFonts = [...new Set(fonts)];
  //   const link = document.createElement('link');
  //   link.href = `https://fonts.googleapis.com/css2?${uniqueFonts.map(font => `family=${font.replace(/\s+/g, '+')}`).join('&')}&display=swap`;
  //   link.rel = 'stylesheet';
  //   document.head.appendChild(link);
  // }

  checkForThemeUpdate(host: string) {
    const path: string | undefined = this.domainMap.get(host);

    if (path) {
      this.afs.collection<ThemingConfig>('themeConfigs').doc<ThemingConfig>(path).get().toPromise()
        .then((docSnapshot) => {
          if (docSnapshot?.exists) {
            const themeConfig = docSnapshot.data() as ThemingConfig;

            this.brandingConfig.next(themeConfig);
            console.log(themeConfig)

          } else {
            console.log('No theme configuration found for this host');
          }
        })
        .catch((error) => {
          console.error('Error fetching theme configuration:', error);
        });
    } else {
      console.log('No path found for the given host');
    }
  }
}
