<div class="flex flex-col items-center justify-center">
  <div class="flex flex-col w-full h-full gap-20">
    <h1 class="text-28">Change Email</h1>
    <tandem-form-input [controlName]="'email'"
                       [form]="emailForm"
                       label="New Email"
                       [inputId]="'email'"
                       placeholder="New Email"
                       [inputType]="'email'"></tandem-form-input>
    <p>Please enter a new email. Keep in mind this operation requires a recent sign-in, <br>so you might be prompted to log in again with your current email before you're able to set a new one</p>
    <div class="flex flex-row justify-center gap-15">
      <tandem-button theme="outline" color="red" [onClick]="close.bind(this)">Cancel</tandem-button>
      <tandem-button theme="outline" color="primary" [onClick]="onSubmit.bind(this)" [disabled]="emailForm.invalid">Change Email</tandem-button>
    </div>
  </div>
</div>
