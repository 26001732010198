import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "../../../../auth/services/auth.service";
import { TandemUser } from "../../../../auth/models/tandem-user";
import { Chat } from "../comment-panel/comment-panel.component";
import { Timestamp } from "firebase/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {UserService} from "../../../../auth/user.service";
import {ChatMessageService} from "../../../services/chat-message.service";
import {ChatMessage} from "../../../models/chat-message";

@Component({
  selector: 'tandem-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit {
  user: TandemUser | null = null;
  chatWindowExpanded = true;
  selectedChat: string | null = null;
  availableUsers: TandemUser[] = [];
  filteredUsers: TandemUser[] = [];
  showUserSelection = false;

  showCancelForUser = false;

  chats: {[key: string]: {
      userId: string;
      userName: string;
      userPhotoURL: string | null;
      lastSeen: Date;
      chatForm: FormGroup;
      messages: ChatMessage[];
    }} = {};

  userSearchForm: FormGroup = this.fb.group({username: null})

  coach?: TandemUser;

  @ViewChild('chatContainer') chatContainer?: ElementRef;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private userService: UserService,
    private chatMessageService: ChatMessageService
  ) {}

  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      this.user = user;
      if (user) {
        if (user.accountType === 'coach') {
          this.userService.getUsersByCoachId(user.uid).subscribe(users => {
            this.availableUsers = users;
            this.filteredUsers = users;
          });
        } else if (user.coachId) {
          this.userService.get(user.coachId).subscribe(coach => {
            this.coach = coach;
          })
        }
      }
    });
    this.userSearchForm.get('username')?.valueChanges.subscribe(val => {
      this.onSearchInput(val);
    })
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.chatContainer!.nativeElement.scrollTop = this.chatContainer!.nativeElement.scrollHeight;
    } catch(err) {
      console.error('Could not scroll to bottom:', err);
    }
  }

  selectChat(chatId: string) {
    this.selectedChat = chatId;
    this.chatWindowExpanded = true;
    this.showUserSelection = false;
    setTimeout(() => this.scrollToBottom(), 5);
  }

  toggleChatExpanded() {
    this.chatWindowExpanded = !this.chatWindowExpanded;
    if (!this.chatWindowExpanded) {
      this.showUserSelection = false;
    }
  }

  showNewChatSelection() {
    if (this.coach) {
      if (!this.showCancelForUser) {
        this.selectUser(this.coach);
        this.showCancelForUser = true;
      } else {
        this.showCancelForUser = false;
      }
    } else {
      this.selectedChat = null;
      this.userSearchForm.reset();
      this.showUserSelection = true;
      this.filteredUsers = this.availableUsers;
    }
  }

  closeUserSelection() {
    this.showUserSelection = false;
    this.filteredUsers = this.availableUsers;
    this.userSearchForm.reset();
  }

  onSearchInput(val: string) {
    this.filteredUsers = this.availableUsers.filter(user =>
      user.firstName?.toLowerCase().includes(val) ||
      user.lastName?.toLowerCase().includes(val)
    );
  }

  selectUser(user: TandemUser) {
    console.log(user)
    if (!this.chats[user.uid]) {
      // Initialize chat window
      this.chats[user.uid] = {
        userId: user.uid,
        userName: `${user.firstName} ${user.lastName}`,
        userPhotoURL: user.photoURL || null,
        lastSeen: new Date(), // You might want to get this from the user object
        chatForm: this.fb.group({message: ['', Validators.required]}),
        messages: []
      };
      // now listen to chat changes
      this.chatMessageService.getMessagesForMembers(this.user!.uid, user.uid).subscribe(chats => {
        console.log('messages found!')
        console.log(this)
        this.chats[user.uid].messages = chats;
      })
    }
    this.selectedChat = user.uid;
    this.showUserSelection = false;
    this.userSearchForm.reset();
    this.scrollToBottom();
  }

  closeChat(userId: string) {
    delete this.chats[userId];
    if (this.selectedChat === userId) {
      this.selectedChat = null;
    }
    if (this.showCancelForUser) {
      this.showCancelForUser = false;
    }
  }

  minimizeChat(chatId: string) {
    this.selectedChat = null;
  }

  sendMessage(chatId: string) {
    if (this.chats[chatId].chatForm.valid) {
      const message = this.chats[chatId].chatForm.get('message')!.value;
      this.chatMessageService.add({
        date: Timestamp.now(),
        status: 'Delivered',
        message: message,
        recipientId: this.chats[this.selectedChat || ''].userId,
        senderId: this.user?.uid || '',
        dateCreated: Timestamp.now(),
        dateModified: Timestamp.now(),
      })
      this.chats[chatId].messages.push();
      this.chats[chatId].chatForm.reset();
      this.scrollToBottom();
    }
  }
}
