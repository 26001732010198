import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

  private _dropLists = new BehaviorSubject<string[]>([]);
  public dropLists = this._dropLists.asObservable();

  private _isDragging = new BehaviorSubject<boolean>(false);
  public isDragging = this._isDragging.asObservable();
  dataChange: Subject<void> = new Subject<void>();
  dataChange$: Observable<void> = this.dataChange.asObservable();

  constructor() { }

  addSubscription(key: string, sub: Subscription) {
    this.subscriptions.get(key)?.unsubscribe();
    this.subscriptions.set(key, sub);
  }

  removeSubscription(key: string) {
    this.subscriptions.get(key)?.unsubscribe();
    this.subscriptions.delete(key);
  }

  clearAllSubscriptions() {
    this.subscriptions.forEach((subscription, key) => {
      subscription.unsubscribe();
      this.subscriptions.delete(key);
    });
  }

  registerDropList(id: string) {
    const current = this._dropLists.value;
    if (current.indexOf(id) === -1) {
      this._dropLists.next([...current, id]);
    }
  }

  startDrag() {
    this._isDragging.next(true);
  }

  stopDrag() {
    this._isDragging.next(false);
  }
}
