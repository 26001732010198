<form [formGroup]="statementItemForm"
      class="flex flex-col w-full"
      (ngSubmit)="onFormSubmit()"
      [appEnterKeySubmit]="statementItemForm"
      [onSubmit]="onSubmit.bind(this)"
>
  <h2 class="text-center text-xl">{{inputTitle}}</h2>
  <tandem-form-input class="w-full"
                     [autoFocus]="true"
                     [form]="statementItemForm"
                     [label]="'Name'"
                     [inputId]="'name'"
                     [controlName]="'name'"
                     [placeholder]="'Name'"></tandem-form-input>
  <tandem-form-input class="w-full"
                     [form]="statementItemForm"
                     [label]="'Value'"
                     [inputId]="'value'"
                     [controlName]="'value'"
                     [placeholder]="'Value'"
                     [inputType]="'number'"
                     [prefixText]="'$'"></tandem-form-input>
  <tandem-form-input *ngIf="requireDate" class="w-full"
                     [form]="statementItemForm"
                     [label]="'Date'"
                     [inputId]="'date'"
                     [controlName]="'date'"
                     [placeholder]="'Date'"
                     [inputType]="'date'"></tandem-form-input>
  <div class="flex flex-row w-full justify-center gap-15 mt-10 gap-x-4">
    <tandem-button color="white" [onClick]="onCancel.bind(this)">Cancel</tandem-button>
    <tandem-button type="submit" theme="solid" [onClick]="onSubmit.bind(this)" [disabled]="statementItemForm.invalid">Save</tandem-button>
  </div>
</form>
