import {Component, OnInit} from '@angular/core';
import {PaymentService} from "../../services/payment-service";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../auth/services/auth.service";
import {take} from "rxjs/operators";
import {TandemUser} from "../../../auth/models/tandem-user";
import {StripeOperationsService} from "../../services/stripe-operations.service";

@Component({
  selector: 'tandem-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  user: TandemUser | null = null;
  sessionId: string | null = null;
  errorMessage?: string;
  isLoaded = false;

  constructor(private auth: AuthService,
              private stripeOperationsService: StripeOperationsService,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private route: ActivatedRoute,
              private router: Router) {
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');
  }
  ngOnInit(): void {
    this.auth.$user.pipe(take(1)).subscribe(user => {
      this.user = user;
      this.headerService.setConfig({hideHeader: true});
      if (this.sessionId) {

        if (this.user?.coachType === 'coachLeadGen') {
          this.stripeOperationsService.validateStripeForLGCoach(this.sessionId).subscribe(res => {
            // Check if the user's email is verified
            this.auth.userPermsSubject.next(); // Trigger userPerms update
            this.router.navigate(['/dashboard']);        })
        } else if (this.user?.accountType === 'user') {
          this.stripeOperationsService.validateStripeForUserOfRSCoach(this.sessionId, this.user.coachId || '').subscribe(res => {
            // Check if the user's email is verified
            this.auth.userPermsSubject.next(); // Trigger userPerms update
            this.router.navigate(['/dashboard']);        })
        }
// this.paymentService.validateAccessFromSession(this.sessionId).then(res => {
        //
        // }).catch(err => {
        //   if (err.statusText == 'Unknown Error') {
        //     this.errorMessage = 'Something went wrong on our end. Please refresh the page. If the error persists, contact customer support.'
        //   } else {
        //     this.errorMessage = err;
        //   }
        // })
      }
    });

  }

}
