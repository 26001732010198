import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DialogService} from "../../../services/dialog.service";
import {FormControl} from "@angular/forms";
import {BehaviorSubject, Observable, Subscription} from "rxjs";

@Component({
  selector: 'line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.scss']
})
export class LineItemComponent implements OnInit, OnDestroy {
  @Input() type?: 'draggable' | '';
  @Input() hasCheckbox?: boolean = false;
  @Input() hasMeatball?: boolean = true;
  @Input() deleteConfirmationText: string = '';
  @Input() massCheckObservable: Observable<boolean> = new Observable<boolean>();
  @Input() showMove?: boolean = false;

  dropdownVisible = false;
  private subscription: Subscription = Subscription.EMPTY;

  @Output() onMove = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onCheckboxToggle = new EventEmitter<boolean>();

  @ViewChild('dropdownMenu', {read: ElementRef}) dropdownMenu: ElementRef | undefined;

  isChecked: FormControl = new FormControl<boolean>(false);
  rowDropdownVisible = false;

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.subscription = this.massCheckObservable.subscribe(val => {
      this.isChecked.setValue(val);
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.rowDropdownVisible = false;
    }
  }

    delete($event: MouseEvent) {
        $event.stopPropagation();
      this.onDelete.emit();
    }

  onToggle($event: boolean) {
    this.onCheckboxToggle.emit($event);
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation(); // Prevent click event from propagating to the document
    this.rowDropdownVisible = !this.rowDropdownVisible;
  }
}
